import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { toDate } from '@/util/date.ts';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { ListOrdersReadyForDeliveryOutput } from './list-orders-ready-for-delivery.output.ts';

const path = '/delivery-helper/order-ready';

export const listOrdersReadyForDelivery = async (): Promise<ListOrdersReadyForDeliveryOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<ListOrdersReadyForDeliveryOutput>(url).then((res) =>
      res.data.map((d) => ({
        ...d,
        createdAt: toDate(d.createdAt),
        invoicedAt: toDate(d.invoicedAt),
        voucherEndOn: toDate(d.voucherEndOn),
      })),
    );
  } catch (err) {
    return handleError(path, err);
  }
};

export const useListOrdersReadyForDelivery = (): SWRResponse<ListOrdersReadyForDeliveryOutput> => {
  const url = getUrl(path);
  return swr<ListOrdersReadyForDeliveryOutput>(url, listOrdersReadyForDelivery);
};
