import { UserRole } from '@/enum';
import { userStore } from '@/store/user';

type Output = (role: UserRole) => boolean;

/**
 * Hook personnalisé pour vérifier si l'utilisateur connecté possède un certain rôle.
 * @returns {(role: UserRole) => boolean} - Fonction prenant un rôle en paramètre et retournant vrai si l'utilisateur possède ce rôle, sinon faux.
 */
export const useUserHasRole = (): Output => {
  return userStore((s) => s.hasRole);
};
