import { createTheme } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import round from 'lodash/round';

export enum Font {
  Sans = '"Open Sans", Arial, sans-serif',
}

export enum ChildericColor {
  Primary = '#EA7600',
  Secondary = '#75787b',
  Optionnal = '#141b4d',
}

export const menuColors = [
  '#1C9F95',
  '#2405A0',
  '#5380F1',
  '#3CA6C7', // #64BDD9',
  '#9083FF',
  '#8EBF02', // '#A7DD0C'
  '#EF8562',
  '#F18BB0',
  '#E89025', // #f6a034
];

// Pantone 716C
// #EA7600

// PANTONE Cool Gray 9 C
// #75787b

// Pantone 2766C
// #141b4d

// https://bareynol.github.io/mui-theme-creator/
export const themeConfig = {
  palette: {
    primary: {
      main: ChildericColor.Optionnal, // '#393939',
    },
    secondary: {
      main: ChildericColor.Primary, // '#EA7600',
      light: '#ebe233',
    },
    background: {
      default: '#ffffff',
      // paper: '#f1ffff',
    },
  },
  typography: {
    fontFamily: Font.Sans,
  },
  components: {
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
  },
};

export const theme = createTheme(themeConfig);

export function px2Rem(size: number): number {
  return round(size / 16, 3);
}
