import styled from 'styled-components';

export const StyledTitle = styled.div`
  h1 {
    margin: 12px 0 0;
    padding: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    color: #2b292d;
    display: none;
  }
`;
