export enum MenuLevel1 {
  Dashboard = '/dashboard',
  Admin = '/admin',
  Reseller = '/reseller',
  Product = '/product',
  Compta = '/compta',
  Price = '/price',
  Purchase = '/purchase',
  Customer = '/customer',
  Order = '/order',
  Repair = '/order/repair',
  Delivery = '/order/delivery',
  Voucher = '/voucher',
}

export const menuLevel1Values = Object.values(MenuLevel1);
