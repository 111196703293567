import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

type UseRedirectToLoginOutput = () => any;

export const useRedirectToLogin = (): UseRedirectToLoginOutput => {
  const navigate = useNavigate();
  const location = useLocation();

  const url = useMemo(() => {
    const disabled = ['/logout'];
    if (!location.pathname) return null;
    if (disabled.includes(location.pathname)) return null;

    const u = [location.pathname, location.search, location.hash].join('');

    return encodeURIComponent(u);
  }, [location]);

  return useCallback(() => {
    if (!url) navigate(`/`);
    if (url) navigate(`/?redirect=${url}`);
  }, [navigate, url]);
};
