import { SnackbarKey } from 'notistack';
import { createContext } from 'react';

export type SnackVariant = 'success' | 'error' | 'warning' | 'info' | 'default';

export type SnackAction = {
  label: string;
  onClick: (close: () => any) => any;
};

export type SnackContextType = {
  success(message: string, actions?: SnackAction[]): SnackbarKey | false;
  error(message: string, actions?: SnackAction[]): SnackbarKey | false;
  info(message: string, actions?: SnackAction[]): SnackbarKey | false;
  warning(message: string, actions?: SnackAction[]): SnackbarKey | false;
  send(message: string, variant: SnackVariant, actions?: SnackAction[]): SnackbarKey | false;
};

export const SnackContext = createContext<SnackContextType>({
  success: () => false,
  error: () => false,
  info: () => false,
  warning: () => false,
  send: () => false,
});
