import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { GetUserOutput } from './get-user.output.ts';

const path = '/user/:userId';

export const getUser = async (userId: string): Promise<GetUserOutput> => {
  try {
    await uuidValidator.validate(userId);
    const url = getUrl(path, { userId });
    return await axios.get<GetUserOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, { userId });
  }
};

export const useGetUser = (userId: string | null): SWRResponse<GetUserOutput> => {
  const url = userId ? getUrl(path, { userId }) : null;
  return swr<GetUserOutput>(url, () => getUser(userId as string));
};
