import { UserRole } from './user-role.enum';

export type UserChildericRole =
  | UserRole.Admin
  | UserRole.Commercial
  | UserRole.Compta
  | UserRole.Delivery
  | UserRole.God
  | UserRole.Panel
  | UserRole.Payment
  | UserRole.Price
  | UserRole.Purchase
  | UserRole.RepairRead
  | UserRole.RepairCreate
  | UserRole.Sms
  | UserRole.Stock
  | UserRole.Voucher
  | UserRole.VoucherAdmin
  | UserRole.Tree;

export const userChildericRoleValues: UserChildericRole[] = [
  UserRole.Admin,
  UserRole.Commercial,
  UserRole.Compta,
  UserRole.Delivery,
  UserRole.God,
  UserRole.Panel,
  UserRole.Payment,
  UserRole.Price,
  UserRole.Purchase,
  UserRole.RepairRead,
  UserRole.RepairCreate,
  UserRole.Sms,
  UserRole.Stock,
  UserRole.Tree,
  UserRole.Voucher,
  UserRole.VoucherAdmin,
];
