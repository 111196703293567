import styled from 'styled-components';

export const StyledImpersonate = styled.li`
  display: flex;
  align-items: center;
  margin: 7px;
  button {
    margin: 0;
    padding: 0;
  }
`;
