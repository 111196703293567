import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { GetNewCustomerByMonthOutput } from './get-new-customer-by-month.output.ts';

const path = '/stat/customer/new-by-month';

export const getNewCustomerByMonth = async (): Promise<GetNewCustomerByMonthOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<GetNewCustomerByMonthOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useGetNewCustomerByMonth = (): SWRResponse<GetNewCustomerByMonthOutput> => {
  const url = getUrl(path);
  return swr<GetNewCustomerByMonthOutput>(url, () => getNewCustomerByMonth());
};
