export enum PaymentType {
  Deposit = 'deposit',
  Step = 'step',
  Balance = 'balance',
}

export const paymentTypeValues = Object.values(PaymentType);

export const paymentTypeOptions = paymentTypeValues.map((m) => ({
  label: `paymentType.${m}`,
  value: m,
}));
