import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@/enum';
import { I18nNs } from '@/util/i18n';

import { BaseStatusIcon, useOrderStatusIconAndColor } from '../BaseStatusIcon';

type Props = {
  status: OrderStatus;
  size?: number;
  isCredit?: boolean;
};

export function OrderStatusIcon({ status, size = 24, isCredit }: Props) {
  const { t } = useTranslation(I18nNs.Enum);

  const { Icon, color } = useOrderStatusIconAndColor(status);

  if (isCredit) {
    return (
      <BaseStatusIcon
        title={t(`orderStatus.${status}`)}
        icon={MonetizationOnIcon}
        color={color}
        size={size}
      />
    );
  }

  return (
    <BaseStatusIcon title={t(`orderStatus.${status}`)} icon={Icon} color={color} size={size} />
  );
}
