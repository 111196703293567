import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React, { useCallback, useMemo, useState } from 'react';

import Suspense from '@/component/Suspense';
import lazy from '@/util/lazy';

import { ChatContext, OpenChatDto } from './ChatContext.ts';

const ChatRoom = lazy(() => import('@/component/ChatRoom/ChatRoom'));

type Props = {
  children: React.ReactNode;
};

export function ChatProvider({ children }: Props): React.ReactElement {
  const [config, setConfig] = useState<OpenChatDto | null>(null);

  const openChat = useCallback((dto: OpenChatDto | null) => {
    setConfig(dto);
  }, []);

  const value = useMemo(() => ({ openChat }), [openChat]);

  const open = !!(config?.docId && config?.type);

  return (
    <ChatContext.Provider value={value}>
      {children}
      <Drawer sx={{ zIndex: 99998 }} anchor="right" open={open} onClose={() => setConfig(null)}>
        {open && (
          <Box sx={{ width: '400px', height: '100%' }}>
            <Suspense>
              <ChatRoom
                types={[config?.type]}
                docId={config?.docId}
                description={config?.description}
                onClose={() => setConfig(null)}
              />
            </Suspense>
          </Box>
        )}
      </Drawer>
    </ChatContext.Provider>
  );
}
