export enum OrderLineStatus {
  // en prod
  Production = 'production',

  // Fini, en attente d'être imprimé
  Print = 'print',

  // Fini
  Finish = 'finish',
}

export const orderLineStatusValues = Object.values(OrderLineStatus);
