import printJSUpdated from 'print-js-updated';

export function printPdf(b: ArrayBuffer) {
  const blob = new Blob([b], { type: 'application/pdf' });
  const blobURL = URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      if (iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    }, 1);
  };
}

export function printPdfFromURL(url: string) {
  return printJSUpdated(url);
}
