import { Paper, Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { useGetSaleByCountry } from '@/api/stat/get-sale-by-country';
import { useCountry } from '@/hook';
import { I18nNs } from '@/util/i18n';
import { price } from '@/util/price';

import { useKey } from '../../hook/useKey';
import { StatNoData } from '../StatNoData/StatNoData';

type Props = {
  id: string;
};

export function StatSaleByCountry({ id }: Props) {
  const { data, isLoading } = useGetSaleByCountry();
  const { t } = useTranslation(I18nNs.Stat);
  const [noData, setNoData] = useState<boolean>(false);
  const country = useCountry();

  const key = useKey();

  const series: ApexOptions['series'] = useMemo(() => {
    if (!Array.isArray(data)) return [];
    if (!key) return [];

    return [
      {
        data: (data || []).map((d) => ({
          x: country[d.country],
          y: d.ca,
        })),
      },
    ];
  }, [data, country, key]);

  const options: ApexOptions | null = useMemo(() => {
    if (!key) return null;
    if (!Array.isArray(data)) return null;
    if (data.length === 0) {
      setNoData(true);
      return null;
    }

    return {
      legend: {
        show: false,
      },
      chart: {
        id,
        height: 160,
        type: 'treemap',
      },
      colors: ['#f25e2f'],
      yaxis: {
        labels: {
          formatter: (val: number) => price(val, { currency: data[0].currency }),
        },
      },
      title: {
        text: t('saleByCountry'),
      },
    };
  }, [id, t, data, key]);

  if (noData) return <StatNoData />;

  if (!options || isLoading) return <Skeleton variant="rectangular" height={160} />;
  if (options?.colors?.length === 0) return <Skeleton variant="rectangular" height={160} />;

  return (
    <Paper key={key} elevation={3} sx={{ height: 160, position: 'relative' }}>
      <Chart options={options} series={series} type="treemap" height={160} />
    </Paper>
  );
}
