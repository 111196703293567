import { useEffect } from 'react';
import semverValid from 'semver/functions/valid';

import { useGetLastAppVersion } from './useGetLastAppVersion';

const DELAY_15_MIN = 1000 * 60 * 15;

export const useLastAppVersion = (): string | null => {
  const { data: version, mutate } = useGetLastAppVersion();

  useEffect(() => {
    const id = setInterval(() => mutate(), DELAY_15_MIN);

    return () => {
      clearInterval(id);
    };
  }, [mutate]);

  if (!version) return null;
  return semverValid(version) ? version : null;
};
