import { UserRole } from '@/enum';
import { userStore } from '@/store/user';

type Output = (...roles: UserRole[]) => boolean;

/**
 * Hook personnalisé pour vérifier si l'utilisateur connecté possède tout les rôles spécifiés.
 * @returns {(...roles: UserRole[]) => boolean} - Fonction prenant une liste de rôles en paramètre et retournant vrai si l'utilisateur possède tout ces rôles, sinon faux.
 */
export const useUserHasEveryRoles = (): Output => {
  return userStore((s) => s.hasEveryRoles);
};
