export enum MenuLevel2 {
  AdminUser = '/admin/user',
  AdminVoucher = '/admin/voucher',
  AdminReseller = '/admin/reseller',
  AdminConfig = '/admin/config',
  AdminHistory = '/admin/history',

  ResellerConfig = '/reseller/config',
  ResellerUser = '/reseller/user',
  ResellerSecondhandPlace = '/reseller/secondhand-place',
  ResellerStat = '/reseller/stat',
  ResellerPolicyBook = '/reseller/policy-book',
  ResellerOrderFrame = '/reseller/order-frame',

  ProductBrand = '/product/brand',
  ProductType = '/product/type',

  ComptaExport = '/compta/export',
  ComptaVat = '/compta/vat',
  ComptaFamily = '/compta/family',
  ComptaAccountNumber = '/compta/account-number',
  ComptaOrderFrame = '/compta/order-frame',

  PriceType = '/price/type',
  PriceVat = '/price/vat',

  PurchaseOrder = '/purchase/order',
  PurchaseUnit = '/purchase/unit',
  PurchaseCategory = '/purchase/category',
  PurchaseSupplier = '/purchase/supplier',
  PurchaseProduct = '/purchase/product',
  PurchaseReceipt = '/purchase/receipt',
  PurchaseStock = '/purchase/stock-product',
  PurchaseTree = '/purchase/stock-tree',

  OrderList = '/order/list',
  OrderNew = '/order/new',
  OrderPayment = '/order/payment',
  OrderDelivery = '/order/delivery',
  OrderChilderic = '/order/childeric',
  OrderRepair = '/order/repair',
  // OrderSecondhand = '/order/secondhand',
  OrderSaddleStock = '/order/saddle-stock',

  VoucherProd = '/voucher/prod',
  VoucherDivers = '/voucher/divers',
  VoucherSearch = '/voucher/search',
}

export const menuLevel2Values = Object.values(MenuLevel2);
