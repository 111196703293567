export enum HistoryAction {
  Copy = 'copy',
  Create = 'create',
  Delete = 'delete',
  Update = 'update',

  AddOrderInVoucher = 'add_order_in_voucher',
  AddProductsFromVoucher = 'add_products_from_voucher',
  AddTreeInOrderProduct = 'add_tree_in_order_product',
  ChangeDeliveryAddress = 'change_delivery_address',
  ChangeInvoiceAddress = 'change_invoice_address',
  ChangePassword = 'change_password',
  ChangeStatus = 'change_status',
  ChangeWarranty = 'change_warranty',
  Confirm = 'confirm',
  DeletePaymentCard = 'delete_payment_card',
  GenerateInvoice = 'generate_invoice',
  GenerateLabel = 'generate_label',
  GenerateOrder = 'generate_order',
  GenerateQuote = 'generate_quote',
  Login = 'login',
  Pay = 'pay',
  ProductsAreNotReceived = 'products_are_not_received',
  ProductsAreReceived = 'products_are_received',
  RefreshPrice = 'refresh_price',
  Refund = 'refund',
  RemoveOrderFromVoucher = 'remove_order_from_voucher',
  RemoveTreeFromOrderProduct = 'remove_tree_from_order_product',
  ResetInventory = 'reset_inventory',
  SendInvoiceToCustomer = 'send_invoice_to_customer',
  SendOrderToCustomer = 'send_order_to_customer',
  SendOrderToSupplier = 'send_order_to_supplier',
  SendQuoteToCustomer = 'send_quote_to_customer',
  SetSaddleNumber = 'set_saddle_number',
  Sign = 'sign',
  StockIn = 'stock_in',
  StockOut = 'stock_out',
  TreesAreNotReceived = 'trees_are_not_received',
  TreesAreReceived = 'trees_are_received',
  UpdateCommercial = 'update_commercial',
  UpdateDiscount = 'update_discount',
  UpdateMe = 'update_me',
  UpdateMedia = 'update_media',
  UpdatePanel = 'update_panel',
  UpdateQuantity = 'update_quantity',
}

export const historyActionValues = Object.values(HistoryAction);
