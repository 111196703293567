import swr, { SWRResponse } from 'swr';

import { handleError } from '@/util/handle-error';

type Output = {
  version: string;
};

const path = '/version.json';

export const getLastAppVersion = async (): Promise<string | null> => {
  try {
    const res = await fetch(`/version.json?t=${new Date().getTime()}`);
    const { version }: Output = await res.json();
    return version;
  } catch (err) {
    handleError(path, err);
    return null;
  }
};

export const useGetLastAppVersion = (): SWRResponse<string | null> => {
  return swr<string | null>('/version.json', getLastAppVersion);
};
