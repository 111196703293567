import swr, { SWRResponse } from 'swr';

import { getPageToAll } from '@/api/util/page-to-all';
import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { ListProductTypesOutput } from '../list-product-types/index.ts';
import {
  ListAllProductTypesQueryInput,
  listAllProductTypesQueryValidator,
} from './list-all-product-types.input.ts';
import { ListAllProductTypesOutput } from './list-all-product-types.output.ts';

const path = '/product-type';

export const listAllProductTypes = async (
  query?: ListAllProductTypesQueryInput,
): Promise<ListAllProductTypesOutput> => {
  try {
    await listAllProductTypesQueryValidator.validate(query);

    return await getPageToAll<ListAllProductTypesOutput[0]>(async (q) => {
      const url = getUrl(path, { ...query, ...q });
      return axios.get<ListProductTypesOutput>(url).then((res) => res.data);
    });
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListAllProductTypes = (
  query?: ListAllProductTypesQueryInput | null,
): SWRResponse<ListAllProductTypesOutput> => {
  const url = query === null ? null : getUrl(path, query);
  return swr<ListAllProductTypesOutput>(url, () =>
    listAllProductTypes(query as ListAllProductTypesQueryInput),
  );
};
