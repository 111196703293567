import * as yup from 'yup';

// TODO: FR_fr ?

export enum I18n {
  FR = 'fr',
  EN = 'en',
}

export type I18nInput = {
  fr: string;
  en: string;
};

export type I18nOutput = I18nInput;

export const i18nValues = Object.values(I18n);

export const defaultI18nInput = {
  fr: '',
  en: '',
};

export const i18nInputValidatorOptional = (): yup.ObjectSchema<Partial<I18nInput>> =>
  yup.object({
    fr: yup.string().optional(),
    en: yup.string().optional(),
  });

export const i18nInputValidatorDefined = (): yup.ObjectSchema<I18nInput> =>
  yup.object({
    fr: yup.string().defined(),
    en: yup.string().defined(),
  });

export const i18nInputValidatorRequired = (): yup.ObjectSchema<I18nInput> =>
  yup.object({
    fr: yup.string().required(),
    en: yup.string().required(),
  });
