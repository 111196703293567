import styled, { css, keyframes } from 'styled-components';

import { ChildericColor } from '@/util/theme';

const bounceAnimation = keyframes`
  0% {
    transform: translate(60%, -60%) scale(1.1);
  }
  20% {
    transform: translate(60%, -65%) scale(1.3);
  }
  40% {
    transform: translate(60%, -60%) scale(1.1);
  }
  60% {
    transform: translate(60%, -65%) scale(1.3);
  }
  100% {
    transform: translate(60%, -60%) scale(1.1);
  }
`;

export const StyledBadge = styled.span<{ $nb: number; $animate: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: center;
  vertical-align: middle;
  align-items: center;

  svg {
    fill: black;
  }

  .badge {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: ${ChildericColor.Primary};
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0 6px;

    transform: translate(60%, -60%) scale(1.1);
    transform-origin: center;
    ${(props) =>
      props.$animate &&
      css`
        animation: ${bounceAnimation} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      `}

    &-text {
      color: white;
      flex: 1;
      text-align: center;
      vertical-align: center;
      position: relative;
      font-size: 12px;
      place-content: center;
      top: -2px;
      font-weight: 500;
      padding: 0px;
      margin: 0px;
    }
  }
`;
