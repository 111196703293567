import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLinkTargetBlank = styled(Link)`
  display: inline-block;

  .ellipsis {
    overflow: hidden;
    hyphens: auto;
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 1);
    -webkit-box-orient: vertical;
  }
`;
