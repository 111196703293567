export enum PurchaseOrderStatus {
  Draft = 'draft',
  Sent = 'sent',
  Confirmed = 'confirmed',
  Received = 'received',
  Checked = 'checked',
  Archived = 'archived',
}

export const purchaseOrderStatusValues = Object.values(PurchaseOrderStatus);
