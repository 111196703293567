export enum ComptaArticleFamilyType {
  Payment = 'payment',
  Product = 'product',
  Secondhand = 'secondhand',
  Recovery = 'recovery',
  Shipping = 'shipping',
  Vat = 'vat',
}

export const comptaArticleFamilyTypeValues = Object.values(ComptaArticleFamilyType);
