import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const StyledNoLevel2 = styled.div`
  width: 100%;
  border-top: 4px solid rgba(20, 27, 77, 0.1);
`;

export const StyledLevel2 = styled(ScrollContainer)`
  position: relative;
  list-style-type: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
`;
