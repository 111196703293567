import { Alert } from '@mui/material';
import { ComponentType, lazy as _lazy } from 'react';

import { i18n,I18nNs } from '@/util/i18n';
import { Logger } from '@/util/logger';

import { config } from '../config';
import { Image, LazyContainer } from './lazy.style';

const logger = new Logger('component/Lazy');

const lazy = function lazy(factory: any) {
  if (config.ENV === 'development') return _lazy(factory);
  return _lazy(() => factory().catch(importErrorHandler));
} as typeof _lazy;

export { lazy };
export default lazy;

let hasError = false;
function importErrorHandler(error: Error): { default: ComponentType<unknown> } {
  const time = Number(window.location.hash.match(/#s(\d+)/)?.[1]);
  const now = Date.now();

  logger.error(error);

  const isReloading = !time || time + 10_000 < now;

  if (isReloading) {
    window.location.hash = `#s${now}`;
    window.location.reload();
  }

  const module = {
    default: () => {
      if (hasError) return null;
      hasError = true;

      return (
        <LazyContainer>
          <div style={{ textAlign: 'center' }}>
            <Image src="/logo100.png" alt={i18n.t('logo', { ns: I18nNs.Common })} />
            <Alert severity="error" sx={{ mt: '20px' }}>
              {i18n.t('errorFallback.title', { ns: I18nNs.Component })}
            </Alert>
          </div>
        </LazyContainer>
      );
    },
  };

  return module;
}
