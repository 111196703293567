import { createContext } from 'react';

import { ChatType } from '@/enum';

export type OpenChatDto = {
  type: ChatType;
  docId: string;
  title: string;
  description?: string;
};

export type ChatContextType = {
  openChat: (dto: OpenChatDto | null) => any;
};

export const ChatContext = createContext<ChatContextType>({
  openChat: () => false,
});
