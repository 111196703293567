import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { ReactElement } from 'react';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // background: 'rgba(242, 94, 47, 0.2)',
    background: '#fcdfd5',
    padding: '3px 10px',
    textAlign: 'center',
    borderRadius: '21px',
    fontFamily: '"Open Sans", Arial, sans-serif',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    letterSpacing: '1px',
  },
}));

type Props = {
  children: ReactElement;
  title?: string | null;
};

export default function Tooltip({ title, children }: Props) {
  if (!title) {
    return children;
  }

  if (typeof title === 'string' && title.trim() === '') {
    return children;
  }

  return <StyledTooltip title={title}>{children}</StyledTooltip>;
}

export { Tooltip };
