import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { I18nNs } from '@/util/i18n';

import { LoginForm } from './component';
import { StyledLoginScene } from './LoginScene.style';

export default function LoginScene() {
  const { t } = useTranslation(I18nNs.Common);
  return (
    <StyledLoginScene>
      <Typography sx={{ textAlign: 'center', mb: 5, mt: 1 }} component="h2" variant="h5">
        {t('connection')}
      </Typography>

      <LoginForm />
      <Stack sx={{ mt: 8 }} justifyContent="flex-end" direction="row">
        <Button component={Link} to="/forgot" size="small" endIcon={<ArrowForwardIosIcon />}>
          {t('forgot.password')}
        </Button>
      </Stack>
    </StyledLoginScene>
  );
}
