import { useState } from 'react';
import { useDebounce } from 'react-use';

import Container from '@/component/Container';
import { useTitle } from '@/context';

import { StyledTitle } from './Title.style';

export function Title() {
  const { title } = useTitle();
  const [debouncedTitle, setDebouncedTitle] = useState<string | null>(null);

  useDebounce(
    () => {
      setDebouncedTitle(title);
    },
    30,
    [title],
  );

  return (
    <Container>
      <StyledTitle>
        <h1>{debouncedTitle}</h1>
      </StyledTitle>
    </Container>
  );
}
