import swr, { SWRResponse } from 'swr';

import { axios, silentAxios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { GetMeOutput } from './get-me.output.ts';

const path = '/auth/me';

export const getMe = async (silent: boolean = true): Promise<GetMeOutput> => {
  try {
    const url = getUrl(path);
    if (silent) {
      return await silentAxios.get<GetMeOutput>(url).then((res) => res.data);
    }

    return await axios.get<GetMeOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err);
  }
};

export const useGetMe = (): SWRResponse<GetMeOutput> => {
  const url = getUrl(path);
  return swr<GetMeOutput>(url, getMe, {});
};
