import { axios, silentAxios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { RefreshOutput } from './refresh.output.ts';

const path = '/auth/refresh';

export const refresh = async (silent: boolean = true): Promise<string> => {
  try {
    const url = getUrl(path);

    if (silent) {
      return await silentAxios.get<RefreshOutput>(url).then((res) => res.data.xsrf);
    }

    return await axios.get<RefreshOutput>(url).then((res) => res.data.xsrf);
  } catch (err) {
    if (silent) throw err;

    return handleError(path, err, {});
  }
};
