import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Tooltip from '@/component/Tooltip';
import { useUserAvatarUrl, useUserLogin } from '@/hook/user';
import { config } from '@/util/config';
import { I18nNs } from '@/util/i18n';

import {
  StyledAvatarButton,
  StyledAvatarMenu,
  StyledAvatarMenuContainer,
  StyledMe,
  StyledMeLi,
} from './Me.style';

export function Me() {
  const [show, setShow] = useState<boolean>(false);
  const login = useUserLogin();
  const avatar = useUserAvatarUrl();
  const { t } = useTranslation(I18nNs.Menu);

  const onMouseLeave = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <StyledMeLi>
      <StyledMe onMouseLeave={onMouseLeave}>
        <Tooltip title={t('popin.profil.show')}>
          <StyledAvatarButton onClick={() => setShow((s) => !s)}>
            {avatar && <img alt={login || '-'} src={avatar} />}
          </StyledAvatarButton>
        </Tooltip>

        {show && (
          <StyledAvatarMenuContainer>
            <StyledAvatarMenu>
              <li>
                <Link to="/me/profil">{t('menu.me.profil')}</Link>
              </li>
              <li>
                <Link to="/me/password">{t('menu.me.password')}</Link>
              </li>
              <li>
                <Link to="/logout">{t('menu.me.logout')}</Link>
              </li>
              <li>
                <span>v{config.APP_VERSION}</span>
              </li>
            </StyledAvatarMenu>
          </StyledAvatarMenuContainer>
        )}
      </StyledMe>
    </StyledMeLi>
  );
}
