import { createGlobalStyle } from 'styled-components';

import { Font } from './util/theme';

export const AppGlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    font-family: ${Font.Sans};
  }

  body {
    margin: 0;
    padding: 0;
  }

  .snackbarPosition {
    margin-top: 60px;
  }

  /*
  form {
    margin-top: -16px;
  }
  */

  .MuiDialog-root,
  .MuiPopper-root,
  .MuiPickersPopper-root,
  .MuiPopover-root   {
    z-index: 999998 !important;
  }

  .MuiAutocomplete-popper {
    z-index: 999999 !important;
  }
`;
