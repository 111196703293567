export enum SecondhandStatus {
  /**
   * Un client propose une reprise mais devis pas encore validé
   */
  Draft = 'draft',

  /**
   * Devis validé par la reprise, la selle est en vente (occasion)
   */
  ForSale = 'for-sale',

  /**
   * Un client réserve la selle dans un devis qui n'est pas encore validé
   */
  Reserved = 'reserved',

  /**
   * Devis occasion validé, la selle est vendu
   */
  Sold = 'sold',

  /**
   * Selle archivée, n'est plus accessible à la vente
   */
  Archived = 'archived',

  /**
   * Autre status (démo, occasion, stock...)
   */
  Other = 'other',
}

export const secondhandStatusValues = Object.values(SecondhandStatus);
