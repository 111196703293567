import { userStore } from '@/store/user';

/**
 * Hook personnalisé pour récupérer l'ID du revendeur associé à l'utilisateur connecté.
 * @returns {string | null} - L'ID du revendeur associé à l'utilisateur, ou null s'il n'est pas associé à un revendeur.
 */

export const useUserResellerId = (): string | null => {
  return userStore((s) => s.user?.resellerId || null);
};
