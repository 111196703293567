import styled from 'styled-components';

export const StyledBaseStatusIcon = styled.div<{ $color: string; $size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $color }) => $color};
  width: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  min-height: ${({ $size }) => $size}px;
  border-radius: 50%;
`;
