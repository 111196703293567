import styled, { createGlobalStyle } from 'styled-components';

export const StyledPreprod = createGlobalStyle`
  body {
  background-image: url('/preprod.svg');
  background-repeat: repeat;
  background-attachment: fixed;
  }
`;

export const StyledConnectedLayoutGlobal = createGlobalStyle`
  body {
    overflow-y: scroll;
    padding-bottom: 24px;
  }

  .MuiTabs-root  {
    button {
      font-size: 14px;
      text-transform: none;
      font-family: 'Open Sans', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: rgba(43, 41, 45, 0.7);

      &:hover {
        color: rgba(43, 41, 45, 0.85);
      }

      &.Mui-selected {
        color: #2B292D;
      }
    }
  }


`;

export const StyledConnectedLayout = styled.div`
  .connected-outlet {
    padding-top: 15px;
  }
`;
