import * as Sentry from '@sentry/react';
import { isValid, toDate as toDateFns } from 'date-fns';

export function toDate(input: Date): Date;
export function toDate(input: Date | null): Date | null;
export function toDate(input: string): Date;
export function toDate(input: unknown): unknown {
  if (input instanceof Date) return toDateFns(input);

  try {
    if (typeof input === 'number') {
      return isValid(input) ? toDateFns(input) : null;
    }
    if (typeof input === 'string') {
      return isValid(new Date(input)) ? toDateFns(new Date(input)) : null;
    }
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return null;
  }

  return null;
}
