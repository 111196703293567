import * as yup from 'yup';

import { PageInput, pageValidator } from '../../common/index.ts';

// Linked: show-product-type-all

export type ListProductTypesQueryInput = PageInput & {
  ids?: string[];
  s?: string;
};

export const listProductTypesQueryValidator = yup
  .object({
    ids: yup.array().of(yup.string().uuid().required()).optional(),
    s: yup.string().optional(),
  })
  .concat(pageValidator);
