export enum EventType {
  GenerateOrderComptaExport = 'generate-order-compta-export',
  GenerateChildericOrder = 'generate-childeric-order',
  RefreshOrderPrice = 'refresh-order-price',
  RefreshOrderProductdNb = 'refresh-order-product-nb',

  RefreshOrderVoucher = 'refresh-order-voucher',
  RefreshOrderProductVoucher = 'refresh-order-product-voucher',

  RefreshOrderTotalDelivery = 'refresh-order-total-delivery',
}

export const eventTypeValues = Object.values(EventType);
