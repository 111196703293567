import { axios, silentAxios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { LoginBodyInput, loginBodyValidator } from './login.input.ts';
import { LoginOutput } from './login.output.ts';

const path = '/auth/login';

export const login = async (body: LoginBodyInput, silent: boolean = true): Promise<string> => {
  try {
    await loginBodyValidator.validate(body);
    const url = getUrl(path);

    if (silent) {
      return await silentAxios.post<LoginOutput>(url, body).then((res) => res?.data?.xsrf);
    }

    return await axios.post<LoginOutput>(url, body).then((res) => res?.data?.xsrf);
  } catch (err) {
    return handleError(path, err, { login: body.login, password: 'xxx' });
  }
};
