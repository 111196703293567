import type { ButtonBaseActions } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useCallback, useEffect, useRef } from 'react';

const Transition = forwardRef(function TransitionRef(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  title?: string;
  content: string | ReactElement;
  ok: string | ReactElement;
  ko?: string | false | ReactElement;
  onClick: (response: boolean) => void;
};

export default function Confirm({ title, content, ok, ko, onClick }: Props): ReactElement {
  const ref = useRef<HTMLButtonElement>(null);
  const buttonRef = useRef<ButtonBaseActions>(null);

  const setFocus = useCallback(() => {
    if (ref.current && buttonRef.current) {
      ref.current.focus();
      buttonRef.current.focusVisible();
    } else {
      setTimeout(setFocus, 200);
    }
  }, []);

  useEffect(setFocus, [setFocus]);

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClick(false)}
      sx={{
        zIndex: 99999,
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {typeof content === 'string' && <DialogContentText>{content}</DialogContentText>}
        {typeof content !== 'string' && content}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 20px 20px',
          minWidth: '300px',
        }}
      >
        {ko && (
          <Button variant="contained" onClick={() => onClick(false)}>
            {ko}
          </Button>
        )}
        {!ko && <span />}
        <Button
          action={buttonRef}
          ref={ref}
          variant="contained"
          color="error"
          onClick={() => onClick(true)}
        >
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { Confirm };
