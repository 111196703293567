import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledChatLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  z-index: 2;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 100%;
  }

  svg {
    fill: black;
  }

  span.round {
    position: relative;
    background-color: rgba(65, 73, 126, 0.1);
  }

  &:hover {
    span.round {
      background-color: rgba(65, 73, 126, 0.2);
    }
  }

  &.is-active {
    span.round {
      background-color: #f25e2f;
    }
    svg {
      fill: #ffffff;
    }

    .badge {
      border: 2px solid white;
    }
  }
`;
