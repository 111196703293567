import { useCallback, useEffect, useState } from 'react';
import semverValid from 'semver/functions/valid';

import { versionSocket } from '@/socket/version';

import { useGetLastAppVersion } from './useGetLastAppVersion';

export const useActualApiVersion = (): string | null => {
  const [version, setVersion] = useState<string | null>(null);
  const { mutate } = useGetLastAppVersion();

  const onVersion = useCallback(
    (version: string) => {
      const isValid = semverValid(version);
      setVersion(isValid ? version : null);
      if (isValid) mutate();
    },
    [mutate],
  );

  useEffect(() => {
    versionSocket.on('version', onVersion);
    return () => {
      versionSocket.off('version', onVersion);
    };
  }, [onVersion]);

  return version;
};
