import { axios, silentAxios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

const path = '/auth/logout';

export const logout = async (silent: boolean = true): Promise<void> => {
  try {
    const url = getUrl(path);

    if (silent) {
      await silentAxios.get(url);
    } else {
      await axios.get(url);
    }
  } catch (err) {
    handleError(path, err, { silent });
  }
};
