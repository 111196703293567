import { createContext } from 'react';

export type TitleContextType = {
  title: string | null;
  setTitle: (title: string | null) => any;
};

export const TitleContext = createContext<TitleContextType>({
  title: null,
  setTitle: () => false,
});
