import { Outlet, Route, Routes } from 'react-router-dom';

import Suspense from '@/component/Suspense';
import ConnectedLayout from '@/layout/ConnectedLayout';
import OfflineLayout from '@/layout/OfflineLayout';
import PublicLayout from '@/layout/PublicLayout';
import DashboardScene from '@/scene/DashboardScene/DashboardScene';
import LoginScene from '@/scene/LoginScene/LoginScene';
import { PayScene } from '@/scene/PayScene';
import PublicScene from '@/scene/PublicScene/PublicScene';
import lazy from '@/util/lazy';

const AdminRouter = lazy(() => import('./component/AdminRouter'));
const ComptaRouter = lazy(() => import('./component/ComptaRouter'));
const OrderRouter = lazy(() => import('./component/OrderRouter'));
const PriceRouter = lazy(() => import('./component/PriceRouter'));
const ProductRouter = lazy(() => import('./component/ProductRouter'));
const PurchaseRouter = lazy(() => import('./component/PurchaseRouter'));
const ResellerRouter = lazy(() => import('./component/ResellerRouter'));
const VoucherRouter = lazy(() => import('./component/VoucherRouter'));

const ChatScene = lazy(() => import('@/scene/ChatScene/ChatScene'));
const CustomerScene = lazy(() => import('@/scene/CustomerScene/CustomerScene'));
const ForgotScene = lazy(() => import('@/scene/ForgotScene/ForgotScene'));
const LogoutScene = lazy(() => import('@/scene/LogoutScene/LogoutScene'));
const MeScene = lazy(() => import('@/scene/MeScene/MeScene'));
const RedirectScene = lazy(() => import('@/scene/RedirectScene/RedirectScene'));
const SearchSaddleScene = lazy(() => import('@/scene/SearchSaddleScene/SearchSaddleScene'));
const TestScene = lazy(() => import('@/scene/TestScene/TestScene'));

export function Router() {
  return (
    <Routes>
      <Route
        element={
          <Suspense>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<PublicLayout />}>
          <Route path="/redirect/:table/:id" element={<RedirectScene />} />
          <Route path="/pub/*" element={<PublicScene />} />
          <Route path="/pay/:paymentId" element={<PayScene />} />
        </Route>
        <Route element={<OfflineLayout />}>
          <Route path="/" element={<LoginScene />} />
          <Route path="/forgot/*" element={<ForgotScene />} />
        </Route>
        <Route element={<ConnectedLayout />}>
          <Route path="/test/*" element={<TestScene />} />
          <Route path="/dashboard" element={<DashboardScene />} />
          <Route path="/logout" element={<LogoutScene />} />

          <Route path="/admin/*" element={<AdminRouter />} />
          <Route path="/reseller/*" element={<ResellerRouter />} />
          <Route path="/product/*" element={<ProductRouter />} />
          <Route path="/compta/*" element={<ComptaRouter />} />
          <Route path="/price/*" element={<PriceRouter />} />
          <Route path="/purchase/*" element={<PurchaseRouter />} />

          <Route path="/customer/*" element={<CustomerScene />} />
          <Route path="/order/*" element={<OrderRouter />} />
          <Route path="/voucher/*" element={<VoucherRouter />} />

          <Route path="/me/*" element={<MeScene />} />
          <Route path="/chat/*" element={<ChatScene />} />
          <Route path="/search/*" element={<SearchSaddleScene />} />
        </Route>
      </Route>
    </Routes>
  );
}
