import {
  ListProductTypesQueryInput,
  listProductTypesQueryValidator,
} from '../list-product-types/index.ts';

export type ListAllProductTypesQueryInput = Omit<ListProductTypesQueryInput, 'page' | 'limit'>;

export const listAllProductTypesQueryValidator = listProductTypesQueryValidator.omit([
  'page',
  'limit',
]);
