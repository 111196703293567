export enum ETab {
  Draft = 'draft',
  Validate = 'validate',
  Last = 'last',
  Repair = 'repair',
  Childeric = 'childeric',
  History = 'history',
  Remember = 'remember',
  Payment = 'payment',
}
