import { Box, Stack, SxProps, TextField, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { KeyboardEvent, ReactElement, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import ErrorMessage from '../ErrorMessage';

type Props<T extends FieldValues> = {
  control: Control<T>;
  disabled?: boolean;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
  label: string;
  maxRows?: number;
  name: Path<T>;
  type?: 'text' | 'email';
  placeholder?: string;
  sx?: SxProps;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
  pattern?: string;
  inputMode?: 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
};

export function InputText<T extends FieldValues>({
  control,
  disabled = false,
  label,
  name,
  maxRows,
  type = 'text',
  startAdornment,
  endAdornment,
  sx,
  onKeyUp,
  pattern,
  inputMode,
  placeholder,
}: Props<T>): React.ReactElement {
  const [id] = useState(nanoid());

  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<T>({
    name,
    control,
  });

  return (
    <Stack sx={{ width: '100%' }}>
      <TextField
        id={id}
        fullWidth
        error={!!error}
        variant="outlined"
        InputProps={{
          startAdornment,
          endAdornment,
          onKeyUp,
          inputMode,
        }}
        inputProps={{
          pattern,
        }}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        label={label}
        {...field}
        value={field.value || ''}
        inputRef={ref}
        sx={sx}
      />
      <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
        <ErrorMessage name={name} control={control} />
        <Box />
        {maxRows && (
          <Typography
            variant="caption"
            sx={{ visibility: field.value.length > 0 ? 'visible' : 'hidden' }}
          >
            {field.value.length} / {maxRows}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default InputText;
