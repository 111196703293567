import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledSearchLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 100%;
  }
  svg {
    fill: black;
  }

  span.round {
    background-color: rgba(65, 73, 126, 0.1);
  }

  &:hover {
    span.round {
      background-color: rgba(65, 73, 126, 0.2);
    }
  }

  .badge span {
    top: 8px;
    right: 3px;
    color: white;
  }

  &.is-active {
    span.round {
      background-color: #f25e2f;
    }
    svg {
      fill: #ffffff;
    }

    .badge span {
      display: none;
    }
  }
`;
