import PercentIcon from '@mui/icons-material/Percent';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { useTranslation } from 'react-i18next';

import { useListAllProductTypesByReseller } from '@/api/product/list-all-product-types-by-reseller';
import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useLang } from '@/hook';
import { useUserHasRole, useUserResellerId } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Price() {
  const hasRole = useUserHasRole();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();
  const resellerId = useUserResellerId();
  const { data: productTypes } = useListAllProductTypesByReseller(resellerId || null);
  const lang = useLang();

  if (level1Path !== MenuLevel1.Price) return null;
  if (!hasRole(UserRole.Price)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item label={t('menu.price.vat')} to={MenuLevel2.PriceVat} icon={<PercentIcon />} />
      <Level2Item
        label={t('menu.price.type')}
        to={MenuLevel2.PriceType}
        icon={<PriceCheckIcon />}
      />
      {(productTypes || []).map((productType) => (
        <Level2Item
          key={productType.id}
          label={productType.name[lang]}
          icon={<PriceChangeIcon />}
          to={`/price/product/${productType.id}`}
        />
      ))}
    </StyledLevel2>
  );
}
