import { userStore } from '@/store/user';

/**
 * Hook personnalisé pour récupérer le nom du revendeur associé à l'utilisateur connecté.
 * @returns {string | null} - Le nom du revendeur associé à l'utilisateur, ou null s'il n'est pas associé à un revendeur.
 */

export const useUserResellerName = (): string | null => {
  return userStore((s) => s.reseller?.name || null);
};
