import { config } from '@/util/config';

import { Action, Level1, Level2, Logo } from './component';
import { Home } from './component/Home';
import { StyledMenu, StyledMenuLevel1 } from './Menu.style';

export function Menu() {
  return (
    <StyledMenu $env={config.ENV}>
      <StyledMenuLevel1>
        <Logo />
        <Home />
        <Level1 />
        <Action />
      </StyledMenuLevel1>
      <Level2 />
    </StyledMenu>
  );
}
