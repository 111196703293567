import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import Tooltip from '@/component/Tooltip';
import { useUserFullName, useUserIsImpersonate } from '@/hook/user';
import { userManager } from '@/manager';
import { I18nNs } from '@/util/i18n';

import { StyledImpersonate } from './Impersonate.style';

export function Impersonate() {
  const isImpersonate = useUserIsImpersonate();
  const name = useUserFullName();
  const { t } = useTranslation(I18nNs.User);

  if (!name) return null;
  if (!isImpersonate) return null;

  return (
    <StyledImpersonate>
      <Tooltip title={t('alert.impersonnate.success', { name })}>
        <IconButton size="small" onClick={userManager.stopImpersonate}>
          <AdminPanelSettingsIcon color="error" sx={{ fontSize: '32px' }} />
        </IconButton>
      </Tooltip>
    </StyledImpersonate>
  );
}
