import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useSnack } from '@/context';
import InputPassword from '@/field/InputPassword';
import InputText from '@/field/InputText';
import SubmitButton from '@/field/SubmitButton';
import { useRedirectAfterLogin } from '@/hook/useRedirectAfterLogin';
import { userManager } from '@/manager';
import { handleError } from '@/util/handle-error';
import { I18nNs } from '@/util/i18n';

type AuthLoginDto = {
  login: string;
  password: string;
};

export function LoginForm() {
  const { t } = useTranslation([I18nNs.Field, I18nNs.Common, I18nNs.Alert]);
  const { control, handleSubmit } = useForm<AuthLoginDto>({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: yupResolver(
      yup
        .object({
          login: yup
            .string()
            .required()
            .label(t('login', { ns: I18nNs.Field })),
          password: yup
            .string()
            .required()
            .label(t('password', { ns: I18nNs.Field })),
        })
        .required(),
    ),
  });

  const redirect = useRedirectAfterLogin();
  const snack = useSnack();

  const onSubmit = async (data: AuthLoginDto) => {
    try {
      const user = await userManager.login(data.login, data.password);
      if (user === null) {
        snack.error(t('connection.fail', { ns: I18nNs.Alert }));
      } else {
        snack.success(t('connection.success', { ns: I18nNs.Alert }));
        redirect();
      }
    } catch (err: any) {
      handleError('/scene/LoginScene/component/LoginForm/LoginForm', err);
      snack.error(t('connection.fail', { ns: I18nNs.Alert }));
    }
  };

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <InputText label={t('login', { ns: I18nNs.Field })} name="login" control={control} />

      <InputPassword
        label={t('password', { ns: I18nNs.Field })}
        name="password"
        control={control}
      />

      <SubmitButton control={control} label={t('connection', { ns: I18nNs.Common })} />
    </Stack>
  );
}
