import { Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '@/component/Loader';
import { config } from '@/util/config';

export const PayScene = () => {
  const { paymentId } = useParams();

  const navigate = useNavigate();

  const url = useMemo(() => {
    if (!paymentId) return null;
    return new URL(`/payment/${paymentId}/sherlock-request`, config.URL_API).href;
  }, [paymentId]);

  useEffect(() => {
    if (!url) return;
    if (!navigate) return;
    window.location.href = url;
  }, [url, navigate]);

  return (
    <div>
      <Loader />
      <Stack justifyContent="center" alignItems="center">
        {url && (
          <p>
            Redirect to <a href={url}>{url}</a>
          </p>
        )}
      </Stack>
    </div>
  );
};
