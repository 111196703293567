import styled from 'styled-components';

export const StyledAction = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  list-style-type: none;

  min-width: 165px;

  @media (max-width: 1360px) {
    flex: none;
  }

  li {
    padding: 0 8px;
  }
`;
