import styled from 'styled-components';

export const StyledLang = styled.li`
  display: flex;
  align-items: center;

  button {
    width: 26px;
    height: 26px;

    img {
      object-fit: cover;
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }
  }
`;
