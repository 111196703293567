import * as yup from 'yup';

export type LoginBodyInput = {
  login: string;
  password: string;
};

export const loginBodyValidator = yup
  .object({
    login: yup.string().required(),
    password: yup.string().required(),
  })
  .required();
