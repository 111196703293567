import { Visibility, VisibilityOff } from '@mui/icons-material';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import { nanoid } from 'nanoid';
import React, { useMemo, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';
import { random } from '@/util/random';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  generate?: boolean;
};

export function InputPassword<T extends FieldValues>({
  name,
  control,
  generate = false,
  ...props
}: Props<T>): React.ReactElement {
  const [show, setShow] = useState<boolean>(false);
  const [id] = useState(`${name}_${nanoid()}`);
  const { t } = useTranslation(I18nNs.Field);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const startAdornment = useMemo(() => {
    if (!generate) return null;
    return (
      <InputAdornment sx={{ mr: 3 }} position="start">
        <Tooltip title={t('password.generate')}>
          <IconButton
            aria-label={t('password.generate')}
            onClick={() => field.onChange({ target: { value: random(10) } })}
            edge="end"
          >
            <ShuffleIcon />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }, [generate, field, t]);

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="end" sx={{ mr: 1 }}>
        <Tooltip title={t(`password.${show ? 'hide' : 'show'}`)}>
          <IconButton
            aria-label={t(`password.${show ? 'hide' : 'show'}`)}
            onClick={() => setShow((s) => !s)}
            onMouseDown={(e) => e.preventDefault()}
            edge="end"
          >
            {show ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
    [show, t],
  );

  return (
    <FormControl error={!!error} sx={{ width: '100%' }} variant="outlined">
      <InputLabel htmlFor={id}>{props.label}</InputLabel>
      <OutlinedInput
        id={id}
        type={show ? 'text' : 'password'}
        fullWidth
        {...props}
        {...field}
        value={field.value || ''}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
