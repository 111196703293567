import clsx from 'clsx';

import { Badge } from '@/component/Badge';
import { useHeader } from '@/context';
import { useChatCountUserAssigned } from '@/hook';
import ChatIcon from '@/svg/chat.svg';

import { StyledChatLink } from './Chat.style';

export function Chat() {
  const { isActive } = useHeader();
  const nb = useChatCountUserAssigned();

  return (
    <li>
      <StyledChatLink to="/chat" className={clsx(isActive('/chat') && 'is-active')}>
        <span className="round">
          <Badge value={nb}>
            <ChatIcon />
          </Badge>
        </span>
      </StyledChatLink>
    </li>
  );
}
