import { mutate } from 'swr';

export const mutateRoute = (startWith: string) => (): void => {
  if (typeof startWith !== 'string') return;
  mutate((key: any) => typeof key === 'string' && key.startsWith(startWith));
};

export const mutateAllRoutes = mutateRoute('/');
export const mutateComptaArticleFamilyRoutes = mutateRoute('/compta-article-family');
export const mutateStatRoutes = mutateRoute('/stat');
export const mutateSecondhandRoutes = mutateRoute('/secondhand');
export const mutateHistoryRoutes = mutateRoute('/history');
export const mutateOrderReadyForVoucherRoutes = mutateRoute('/order-helper/ready-for-voucher');
export const mutateTreeRoutes = mutateRoute('/tree');
export const mutateVoucherRoutes = mutateRoute('/voucher');
export const mutateTreeInventoryRoutes = mutateRoute('/tree-inventory');
export const mutateMe = mutateRoute('/auth/me');
