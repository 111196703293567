import Color from 'color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLevel1ItemLink = styled(Link)`
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #000000;
  display: flex;
  height: 100%;
`;

export const StyledLevel1Item = styled.li<{ $menuColor: string }>`
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  text-decoration: none;
  color: #000000;

  font-family: 'Open Sans', Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 24px;

  text-transform: uppercase;
  letter-spacing: 1px;

  span {
    padding: 8px 8px;
    margin: auto 14px;
    border-radius: 21px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 600;
  }

  a:hover span {
    background: rgba(65, 73, 126, 0.1);
  }

  &.is-active span,
  &.is-active a:hover span {
    background: ${({ $menuColor }) => Color($menuColor).alpha(0.2).toString()};
  }

  @media (max-width: 1200px) {
    font-size: 12px;

    span {
      padding: 8px 11px;
      margin: auto 2px;
    }
  }
`;
