import Box from '@mui/material/Box';
import { ReactNode, Suspense as ReactSuspense, useEffect, useState } from 'react';

import Loader from '../Loader';

type Props = {
  children: ReactNode;
};

export const Suspense = ({ children }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setShowLoader(true), 200);
  }, []);

  return (
    <ReactSuspense
      fallback={
        showLoader ? (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Loader />
          </Box>
        ) : null
      }
    >
      {children}
    </ReactSuspense>
  );
};
