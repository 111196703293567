export enum OrderStatus {
  // Brouillon
  Draft = 'draft',

  // Devis, en attente de validation client
  Quote = 'quote',

  // Devis validé, en attente de validation Childéric
  BeingValidated = 'being-validated',

  // Validé par Childéric
  Valid = 'valid',

  // Mise en production
  Production = 'production',

  // Commande en cours de livraison
  Delivery = 'delivery',

  // Commande fini/livré
  Finish = 'finish',

  // Manque d'info dans le devis, retour au commercial
  Incomplete = 'incomplete',

  // Commande annulé
  Cancelled = 'cancelled',
}

export const orderStatusValues = Object.values(OrderStatus);
