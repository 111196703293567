import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { MenuLevel1, MenuLevel2 } from '@/enum';
import { I18nNs } from '@/util/i18n';

import { colors,HeaderContext } from './HeaderContext.ts';

type Props = {
  children: React.ReactNode;
};

export function HeaderProvider({ children }: Props): React.ReactElement {
  const { t } = useTranslation(I18nNs.Menu);
  const location = useLocation();

  const isActive = useCallback(
    (path: string) => {
      if (path === '/order/list') {
        return (
          !!matchPath({ path: '/order/list', end: true }, location.pathname) ||
          !!matchPath({ path: '/order/show', end: false }, location.pathname)
        );
      }

      return !!matchPath({ path, end: false }, location.pathname);
    },
    [location],
  );

  const level1Path = useMemo(
    () => Object.values(MenuLevel1).find((path) => isActive(path)) || null,
    [isActive],
  );

  const level2Path = useMemo(
    () => Object.values(MenuLevel2).find((path) => isActive(path)) || null,
    [isActive],
  );

  const getColor = useCallback((menu: MenuLevel1 | null) => {
    if (!menu) return colors[0];
    const values = Object.values(MenuLevel1);
    const index = values.indexOf(menu);
    const col = colors[index % colors.length];
    if (col) return col;
    return colors[0];
  }, []);

  const color = useMemo(() => getColor(level1Path), [getColor, level1Path]);

  const level1Name = useMemo(() => {
    if (typeof level1Path !== 'string') return null;
    const key = level1Path.replace(/^\//, '').replace(/\//g, '.');
    return t(`menu.${key}`);
  }, [level1Path, t]);

  const level2Name = useMemo(() => {
    if (typeof level2Path !== 'string') return null;
    const key = level2Path.replace(/^\//, '').replace(/\//g, '.');
    return t(`menu.${key}`);
  }, [level2Path, t]);

  const value = useMemo(
    () => ({
      isActive,
      level1Path,
      level2Path,
      level1Name,
      level2Name,
      getColor,
      color,
    }),
    [isActive, level1Name, level2Name, level1Path, level2Path, getColor, color],
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}
