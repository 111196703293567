import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';

import { ETab } from '../enum';

type Props = {
  value: ETab;
  selected?: boolean;
};

export const TabHistory = forwardRef(function TabHistoryRef(props: Props, ref: ForwardedRef<any>) {
  const { t } = useTranslation(I18nNs.Dashboard);
  return (
    <Tab
      ref={ref}
      {...props}
      icon={<VisibilityIcon />}
      iconPosition="start"
      value={ETab.History}
      label={<Typography variant="inherit">{t('tab.see-customer')}</Typography>}
    />
  );
});
