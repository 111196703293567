import React, { useEffect, useState } from 'react';
import { redirect } from 'react-router-dom';

import { getMe } from '@/api/auth/get-me';
import SplashScreen from '@/component/SplashScreen';
import { userStore } from '@/store/user';

type Props = {
  children: React.ReactElement;
};

export function LoadingProvider({ children }: Props): React.ReactElement {
  const [wait, setWait] = useState<boolean>(true);

  useEffect(() => {
    getMe(true)
      .catch(() => {
        return new Promise((resolve) => {
          setTimeout(() => {
            userStore.getState().logout();
            redirect('/');
            resolve(null);
          }, 2000);
        });
      })
      .finally(() => {
        setWait(false);
      });
  }, []);

  if (wait) {
    return <SplashScreen />;
  }

  return children;
}
