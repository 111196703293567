import * as yup from 'yup';

import { I18n, i18nValues } from '@/enum/index.ts';

export type UpdateMeBodyInput = {
  firstName?: string;
  lastName?: string;
  avatarId?: string | null;
  lang?: I18n;
  phone?: string;
  mobile?: string;
  showDocumentsInMyLang?: boolean;
};

export const updateMeBodyValidator = yup
  .object({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    avatarId: yup.string().uuid().optional().nullable(),
    lang: yup.string().oneOf(i18nValues).optional(),
    phone: yup.string().optional(),
    mobile: yup.string().optional(),
    showDocumentsInMyLang: yup.boolean().optional(),
  })
  .required();
