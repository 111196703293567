import { StyledAction } from './Action.style';
import { Chat, Impersonate, Me, Search } from './component';

export function Action() {
  return (
    <StyledAction>
      <Impersonate />
      <Search />
      <Chat />
      <Me />
    </StyledAction>
  );
}
