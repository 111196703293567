import { createContext } from 'react';

export type BreadcrumbDto = {
  label: string;
  path?: string;
  position: number;
  key: string;
};

export type BreadcrumbWithoutKeyDto = Omit<BreadcrumbDto, 'key'>;

export type BreadcrumbContextType = {
  items: BreadcrumbDto[];
  addItem: (breadcrumb: BreadcrumbWithoutKeyDto) => any;
  removeItem: (breadcrumb: BreadcrumbWithoutKeyDto) => any;
};

export const BreadcrumbContext = createContext<BreadcrumbContextType>({
  items: [],
  addItem: () => true,
  removeItem: () => true,
});
