export enum ProductParameterKey {
  DIAMETRE_JONC_ARRIERE = 'diametre_jonc_arriere',
  DIAMETRE_JONC_AVANT = 'diametre_jonc_avant',
  LONGUEUR_JONC_ARRIERE = 'longueur_jonc_arriere',
  LONGUEUR_JONC_AVANT = 'longueur_jonc_avant',
  REF_CAM_PNX_AR = 'ref_cam_pnx_ar',
  REF_CAM_PNX_AVT = 'ref_cam_pnx_avt',
  REF_CAM_SIEGE = 'ref_cam_siege',
  TYPE_FEUTRE_PNX = 'type_feutre_pnx',
  TYPE_MOUSSE_SIEGE = 'type_mousse_siege',
}

export const productParameterKeyValues = Object.values(ProductParameterKey);
