import styled, { keyframes } from 'styled-components';

const pc = 3;

const animate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  ${100 - pc * 4}% {
    transform: rotate(0deg);
  }

  ${100 - pc * 3}% {
    transform: rotate(-10deg);
  }

  ${100 - pc * 2}% {
    transform: rotate(0deg);
  }

  ${100 - pc}% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);

  }
`;

export const Image = styled.img`
  animation: ${animate} 2s linear infinite;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  z-index: 1300000;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;

  div {
    justify-content: center;
  }

  & > div {
    align-self: center;
  }

  p {
    padding: 0;
    margin: 0;
  }
`;

export const LoaderBackground = styled.div<{ $opacity: number }>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-color: white;
  opacity: ${({ $opacity }) => $opacity || 1};
`;
export const LoaderContent = styled.div`
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  /*
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.01), 0px 3px 4px 0px rgba(0, 0, 0, 0.01),
    0px 1px 8px 0px rgba(0, 0, 0, 0.08);
  */

  background: radial-gradient(circle at center, white 0, white 120px, transparent 75%);
  // overflow: hidden;
  p {
    padding: 10px !important;
    text-align: center;
    position: relative;
    left: 4px;
    background-color: #ffffff;
    border-radius: 10px;

    box-shadow: 0 0 14px 14px rgba(255, 255, 255, 1), 0px 3px 4px 0px rgba(255, 255, 255, 1);
  }
`;
