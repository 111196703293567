import styled from 'styled-components';

export const StyledHome = styled.div`
  margin: 0;
  min-width: 80px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(65, 73, 126, 0.1);
      width: 45px;
      height: 45px;
      border-radius: 100%;
    }

    svg {
      fill: #000000;
    }

    &:hover {
      span {
        background-color: rgba(65, 73, 126, 0.2);
      }
    }
  }

  &.is-active {
    svg {
      fill: #ffffff;
    }

    span,
    &:hover span {
      background-color: #f25e2f;
    }
  }
`;
