import { ReactElement, useMemo } from 'react';

import { useHeader } from '@/context';
import { MenuLevel1 } from '@/enum';

import {
  Level2Admin,
  Level2Compta,
  Level2Order,
  Level2Price,
  Level2Product,
  Level2Purchase,
  Level2Reseller,
  Level2Voucher,
} from './component';
import { StyledNoLevel2 } from './Level2.style';

export function Level2(): ReactElement {
  const { level1Path } = useHeader();

  const menuIndex = useMemo(
    () =>
      new Map<MenuLevel1 | null, ReactElement>([
        [MenuLevel1.Admin, <Level2Admin key={MenuLevel1.Admin} />],
        [MenuLevel1.Compta, <Level2Compta key={MenuLevel1.Compta} />],
        [MenuLevel1.Order, <Level2Order key={MenuLevel1.Order} />],
        [MenuLevel1.Price, <Level2Price key={MenuLevel1.Price} />],
        [MenuLevel1.Product, <Level2Product key={MenuLevel1.Product} />],
        [MenuLevel1.Purchase, <Level2Purchase key={MenuLevel1.Purchase} />],
        [MenuLevel1.Reseller, <Level2Reseller key={MenuLevel1.Reseller} />],
        [MenuLevel1.Voucher, <Level2Voucher key={MenuLevel1.Voucher} />],
        [null, <StyledNoLevel2 key="null" />],
      ]),
    [],
  );

  const menu2 = useMemo(() => menuIndex.get(level1Path || null) || null, [menuIndex, level1Path]);

  if (menu2) return menu2;

  return <StyledNoLevel2 />;
}
