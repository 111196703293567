import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { UpdateMeBodyInput, updateMeBodyValidator } from './update-me.input.ts';

const path = '/auth/me';

export const updateMe = async (body: UpdateMeBodyInput): Promise<void> => {
  try {
    await updateMeBodyValidator.validate(body);
    const url = getUrl(path);
    await axios.patch(url, body);
  } catch (err) {
    handleError(path, err, { body });
  }
};
