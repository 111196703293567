export enum PaymentStatus {
  Draft = 'draft',
  ShouldBePaid = 'should-be-paid',
  Pending = 'pending',
  IsPaid = 'is-paid',
  Canceled = 'canceled',
}

export const paymentStatusValues = Object.values(PaymentStatus);

export const paymentStatusOptions = paymentStatusValues.map((m) => ({
  label: `paymentStatus.${m}`,
  value: m,
}));
