import { UserRole } from '@/enum';
import { userStore } from '@/store/user';

type Output = (...roles: UserRole[]) => boolean;

/**
 * Hook personnalisé pour vérifier si l'utilisateur connecté possède au moins l'un des rôles spécifiés.
 * @returns {(...roles: UserRole[]) => boolean} - Fonction prenant une liste de rôles en paramètre et retournant vrai si l'utilisateur possède au moins l'un de ces rôles, sinon faux.
 */
export const useUserHasSomeRole = (): Output => {
  return userStore((s) => s.hasSomeRole);
};
