import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import GroupIcon from '@mui/icons-material/Group';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PrintIcon from '@mui/icons-material/Print';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';

import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useUserHasSomeRole, useUserIsChilderic } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Reseller() {
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();
  const hasSomeRole = useUserHasSomeRole();
  const isChilderic = useUserIsChilderic();

  if (level1Path !== MenuLevel1.Reseller) return null;
  if (!hasSomeRole(UserRole.Admin, UserRole.AdminReseller)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item
        label={t('menu.reseller.user')}
        to={MenuLevel2.ResellerUser}
        icon={<GroupIcon />}
      />
      <Level2Item
        label={t('menu.reseller.config')}
        to={MenuLevel2.ResellerConfig}
        icon={<StorefrontIcon />}
      />
      <Level2Item
        label={t('menu.reseller.stat')}
        to={MenuLevel2.ResellerStat}
        icon={<QueryStatsIcon />}
      />
      {isChilderic && (
        <Level2Item
          label={t('menu.reseller.secondhand-place')}
          to={MenuLevel2.ResellerSecondhandPlace}
          icon={<DirectionsCarIcon />}
        />
      )}

      <Level2Item
        label={t('menu.reseller.order-frame')}
        to={MenuLevel2.ResellerOrderFrame}
        icon={<PrintIcon />}
      />

      <Level2Item
        label={t('menu.reseller.policy-book')}
        to={MenuLevel2.ResellerPolicyBook}
        roles={[UserRole.God, UserRole.Admin]}
        icon={<LocalPoliceIcon />}
      />
    </StyledLevel2>
  );
}
