import swr, { mutate,SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { ListHistoriesBodyInput, listHistoriesBodyValidator } from './list-histories.input.ts';
import { ListHistoriesOutput } from './list-histories.output.ts';

const path = '/history';

export const listHistories = async (body: ListHistoriesBodyInput): Promise<ListHistoriesOutput> => {
  try {
    await listHistoriesBodyValidator.validate(body);
    const url = getUrl(path);
    return await axios.post<ListHistoriesOutput>(url, body).then((res) => ({
      ...res.data,
      docs: res.data.docs.map((d) => ({
        ...d,
        createdAt: toDate(d.createdAt),
      })),
    }));
  } catch (err) {
    return handleError(path, err, { body });
  }
};

export const useListHistories = (
  body: ListHistoriesBodyInput,
): SWRResponse<ListHistoriesOutput> => {
  // this getUrl send bad url (use query and no body), but is a good key for cache key
  const url = getUrl(path, body);
  return swr<ListHistoriesOutput>(url, () => listHistories(body));
};

export async function mutateHistory() {
  mutate((key: any) => typeof key === 'string' && key.startsWith('/history'));
}
