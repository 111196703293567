export enum VoucherSaddleColumn {
  /*
  NOM = 'nom',
  NOM2 = 'nom2',
  */
  MOD = 'mod',
  SIEGE = 'siege',
  SIEGE2 = 'siege2',
  QRT = 'qrt',
  QRT2 = 'qrt2',
  AO = 'ao',
  AV_SG = 'av_sg',
  COUL = 'coul',
  DB = 'db',
  FIL = 'fil',
  CSL = 'csl',
  CSL2 = 'csl2',
  JC = 'jc',
  TAV = 'tav',
  TAR = 'tar',
  TAQ = 'taq',
  PNX = 'pnx',
  PNX_COUL = 'pnx_coul',
  PNX_OPT = 'pnx_opt',
  P = 'p',
  DIVERS = 'divers',
}

export const voucherSaddleColumnValues = Object.values(VoucherSaddleColumn);
