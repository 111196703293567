import semverGte from 'semver/functions/gte';

/**
 * @param {string | null} actualAppVersion
 * @param {string | null} actualApiVersion
 * @param {string | null} lastAppVersion
 */
export const useCanRefresh = (
  actualAppVersion: string | null,
  actualApiVersion: string | null,
  lastAppVersion: string | null,
): boolean => {
  if (actualApiVersion !== lastAppVersion) return false;
  if (actualApiVersion === actualAppVersion) return false;
  if (!actualAppVersion) return false;
  if (!lastAppVersion) return false;
  if (semverGte(actualAppVersion, lastAppVersion)) return false;
  return true;
};
