export enum PaymentMethod {
  CB = 'cb',
  Transfert = 'transfert',
  Cash = 'cash',
  Check = 'check',
}

export const paymentMethodValues = Object.values(PaymentMethod);

export const paymentMethodOptions = paymentMethodValues.map((m) => ({
  label: `paymentMethod.${m}`,
  value: m,
}));
