import styled from 'styled-components';

export const StyledOfflineLayout = styled.div`
  width: 100vw;
  display: grid;
  height: 100vh;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 2fr minmax(600px, 1fr);
  grid-template-areas:
    'img lang'
    'img content';
  align-items: stretch;
`;

export const StyledOfflineLayoutImg = styled.div`
  grid-area: img;
  background-image: url('/manufacture-childeric-sellier-equitation-selles-sur-mesure.jpg');
  background-size: cover;
  background-position: center center;
`;

export const StyledOfflineLayoutLang = styled.div`
  grid-area: lang;
  text-align: right;
  align-self: center;
  margin-right: 20px;
`;

export const StyledOfflineLayoutContent = styled.div`
  grid-area: content;
  padding: 100px 15px 15px;

  .avatar div {
    margin: auto;
    text-align: center;
  }
`;
