import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';

export function StatNoData() {
  const { t } = useTranslation(I18nNs.Dashboard);
  return (
    <Paper elevation={3} sx={{ height: 160, position: 'relative' }}>
      <Typography
        variant="h5"
        sx={{
          fontSize: '14px',
          padding: '5px',
          fontWeight: 'bold',
        }}
      >
        {t('stat-no-data.title')}
      </Typography>
      <Box sx={{ margin: 'auto', textAlign: 'center' }}>
        <TrendingUpIcon fontSize="large" />
      </Box>
      <Typography variant="body1" sx={{ padding: '5px' }}>
        {t('stat-no-data.content')}
      </Typography>
    </Paper>
  );
}
