import Color from 'color';
import styled from 'styled-components';

export const StyledBreadcrumb = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;
`;

export const StyledBreadcrumbItem = styled.li<{ $color: string }>`
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 0 10px;

  &::after {
    position: absolute;
    content: '>';
    right: 0;
    top: calc(50% - 1px);
    transform: translateY(-50%);
  }

  &:last-child::after {
    display: none;
  }

  &:first-child {
    padding: 0 10px 0 0;
  }

  a,
  span {
    display: flex;
    align-items: center;
    color: #000000;
    text-decoration: none;
    padding: 5px 10px;
  }

  &:hover {
    a {
      font-weight: 600;
    }
  }

  &:first-child {
    a,
    span {
      background: ${({ $color }) => Color($color).alpha(0.2).toString()};
      border-radius: 21px;
      margin-right: 15px;
    }
  }
`;
