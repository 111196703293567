import { Socket } from 'socket.io-client';

import { ChatType } from '@/enum';
import { userStore } from '@/store/user';

import { socketManager } from '../manager/socket.manager';
import { ChatClientToServer, ChatServerToClient } from './chat.socket.dto';

export const chatSocket: Socket<ChatServerToClient, ChatClientToServer> = socketManager.socket(
  '/chat',
  {
    auth: (cb) => {
      cb({
        token: userStore.getState().token,
      });
    },
  },
);

chatSocket.on('connect', () => {
  const token = userStore.getState().token;
  if (typeof token === 'string') {
    chatSocket.emit('initialize');
  }
});

chatSocket.io.on('error', (err) => {
  console.error('err', err);
});

userStore.subscribe((state, prevState): void => {
  const hasToken = typeof state.token === 'string';

  chatSocket.auth = (cb) => cb({ token: state.token });

  if (!hasToken) {
    chatSocket.io.engine.close();
    return;
  }

  if (!chatSocket.connected) {
    chatSocket.connect();
    return;
  }

  if (state.token === prevState.token) return;

  chatSocket.io.engine.close();
  chatSocket.connect();
});

export const chatJoin = (type: ChatType, docId: string) => {
  chatSocket.emit('joinRoom', { type, docId });
};

export const chatLeave = (type: ChatType, docId: string) => {
  chatSocket.emit('leaveRoom', { type, docId });
};
