import mitt from 'mitt';

import { GetMeOutput } from '@/api/auth/get-me';

type Events = {
  logout: void;
  user: GetMeOutput | null;
};

export const userEmitter = mitt<Events>();
export default userEmitter;
