import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { UnimpersonateOutput } from './unimpersonate.output.ts';

const path = '/auth/unimpersonate';

export const unimpersonate = async (): Promise<string> => {
  try {
    const url = getUrl(path);
    return await axios.post<UnimpersonateOutput>(url).then((res) => res.data.xsrf);
  } catch (err) {
    return handleError(path, err, {});
  }
};
