import { GetMeOutput } from '@/api/auth/get-me';
import { userStore } from '@/store/user';

type Output = (user: GetMeOutput | null) => void;

/**
 * Hook personnalisé pour définir l'utilisateur connecté.
 * @returns {(user: User | null) => void} - Fonction prenant un objet utilisateur en paramètre et le définissant comme l'utilisateur connecté.
 */
export const useSetUser = (): Output => {
  return userStore((s) => s.setUser);
};
