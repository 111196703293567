import { Settings as SettingsIcon } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useTranslation } from 'react-i18next';

import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useUserHasRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Admin() {
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();
  const hasRole = useUserHasRole();

  if (level1Path !== MenuLevel1.Admin) return null;
  if (!hasRole(UserRole.God)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item label={t('menu.admin.user')} to={MenuLevel2.AdminUser} icon={<GroupIcon />} />

      <Level2Item
        label={t('menu.admin.voucher')}
        to={MenuLevel2.AdminVoucher}
        icon={<TableChartIcon />}
      />

      <Level2Item
        label={t('menu.admin.reseller')}
        to={MenuLevel2.AdminReseller}
        icon={<StorefrontIcon />}
      />

      <Level2Item
        label={t('menu.admin.config')}
        to={MenuLevel2.AdminConfig}
        icon={<SettingsIcon />}
      />

      <Level2Item
        label={t('menu.admin.history')}
        to={MenuLevel2.AdminHistory}
        icon={<HistoryIcon />}
      />
    </StyledLevel2>
  );
}
