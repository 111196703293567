import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTitle as useTitleNav } from 'react-use';

import { TitleContext } from './TitleContext.ts';

type Props = {
  children: React.ReactNode;
};

const cache = new Map<string, string>();

export function TitleProvider({ children }: Props): React.ReactElement {
  const [title, setTitle] = useState<string | null>(null);
  const location = useLocation();

  useTitleNav([title, 'Childéric Sellier'].filter(Boolean).join(' - '));

  useEffect(() => {
    if (cache.has(location.pathname)) {
      setTitle(cache.get(location.pathname) || null);
      return;
    }
    setTitle(null);
  }, [location.pathname]);

  const setTitleWithCache = useCallback(
    (t: string | null) => {
      if (t !== null) cache.set(location.pathname, t);
      setTitle(t);
    },
    [location.pathname],
  );

  const value = useMemo(
    () => ({
      title,
      setTitle: setTitleWithCache,
    }),
    [title, setTitleWithCache],
  );

  return <TitleContext.Provider value={value}>{children}</TitleContext.Provider>;
}
