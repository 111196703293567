import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { GetSaleRollingYearByMonthOutput } from './get-sale-rolling-year-by-month.output.ts';

const path = '/stat/sale/rolling-year';

export const getSaleRollingYearByMonth = async (): Promise<GetSaleRollingYearByMonthOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<GetSaleRollingYearByMonthOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useGetSaleRollingYearByMonth = (): SWRResponse<GetSaleRollingYearByMonthOutput> => {
  const url = getUrl(path);
  return swr<GetSaleRollingYearByMonthOutput>(url, () => getSaleRollingYearByMonth());
};
