import * as yup from 'yup';

// TODO: s can be removed on all methods
// keep it here
export type PageInput = {
  page?: number;
  limit?: number;
  sort?: string | string[];
  fields?: string | string[];
  desc?: string | string[];
  s?: string;
};

// TODO: use YupArrayOrString for mixed type
type YupArrayOrString = string | string[] | undefined;
const yupArrayOrString: yup.Schema<YupArrayOrString> = yup
  .mixed<any>()
  .test(
    'stringOrArray',
    (label: string) => `${label} must be a string or array.`,
    async (value: any) => {
      if (typeof value === 'undefined') return true;

      if (Array.isArray(value)) {
        return yup.array().of(yup.string().required()).isValid(value);
      }
      if (typeof value === 'string') {
        return yup.string().optional().isValid(value);
      }

      return false;
    },
  )
  .optional();

export const pageValidator = yup.object({
  page: yup.number().min(1).optional(),
  limit: yup.number().min(1).optional(),
  sort: yupArrayOrString,
  fields: yupArrayOrString,
  desc: yupArrayOrString,
  s: yup.string().optional(),
});
