import { useTranslation } from 'react-i18next';

import { MenuLevel1, UserRole } from '@/enum';
import {
  useUserHasRole,
  useUserHasSomeRole,
  useUserIsAdminReseller,
  useUserIsGod,
  useUserResellerName,
} from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level1Item } from '../Level1Item/Level1Item';
import { StyledLevel1 } from './Level1.style';

export function Level1() {
  const { t } = useTranslation(I18nNs.Menu);
  const isGod = useUserIsGod();
  const hasSomeRole = useUserHasSomeRole();
  const resellerName = useUserResellerName();
  const isAdminReseller = useUserIsAdminReseller();
  const hasRole = useUserHasRole();

  return (
    <StyledLevel1 component="ul">
      {isGod && <Level1Item label={t('menu.admin')} to={MenuLevel1.Admin} />}

      {resellerName && (isGod || isAdminReseller || hasRole(UserRole.Admin)) && (
        <Level1Item label={resellerName} to={MenuLevel1.Reseller} />
      )}

      {isGod && <Level1Item label={t('menu.product')} to={MenuLevel1.Product} />}

      {hasRole(UserRole.Compta) && <Level1Item label={t('menu.compta')} to={MenuLevel1.Compta} />}

      {hasRole(UserRole.Price) && <Level1Item label={t('menu.price')} to={MenuLevel1.Price} />}

      {hasSomeRole(UserRole.Purchase, UserRole.Stock) && (
        <Level1Item label={t('menu.purchase')} to={MenuLevel1.Purchase} />
      )}

      {hasSomeRole(UserRole.AdminReseller, UserRole.Admin, UserRole.Commercial) && (
        <Level1Item label={t('menu.customer')} to={MenuLevel1.Customer} />
      )}

      {hasSomeRole(UserRole.Commercial, UserRole.Admin, UserRole.AdminReseller) && (
        <Level1Item label={t('menu.order')} to={MenuLevel1.Order} />
      )}

      {!hasSomeRole(UserRole.Commercial, UserRole.Admin, UserRole.AdminReseller) &&
        hasRole(UserRole.RepairRead) && (
          <Level1Item label={t('menu.repair')} to={MenuLevel1.Repair} />
        )}

      {hasRole(UserRole.Delivery) &&
        !hasSomeRole(UserRole.Commercial, UserRole.Admin, UserRole.AdminReseller) && (
          <Level1Item label={t('menu.delivery')} to={MenuLevel1.Delivery} />
        )}

      {hasSomeRole(UserRole.Voucher, UserRole.VoucherAdmin) && (
        <Level1Item label={t('menu.voucher')} to={MenuLevel1.Voucher} />
      )}
    </StyledLevel1>
  );
}
