import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { redirectEmitter } from '@/event/redirect.emitter';

type Props = {
  children: React.ReactElement;
};

export function RedirectProvider({ children }: Props): React.ReactElement {
  const navigate = useNavigate();

  const onRedirect = useCallback((path: string) => navigate(path), [navigate]);

  useEffect(() => {
    redirectEmitter.on('to', onRedirect);
    return () => redirectEmitter.off('to', onRedirect);
  }, [onRedirect]);

  return children;
}
