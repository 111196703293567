import Color from 'color';
import styled from 'styled-components';

export const StyledMeLi = styled.li`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledAvatarButton = styled.button`
  display: block;
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: #ffffff;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 45px;
  height: 45px;

  img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const StyledMe = styled.div`
  position: relative;
  &:hover ${StyledAvatarButton}::after {
    position: absolute;
    border-radius: 100%;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #f25e2f;
  }
`;

export const StyledAvatarMenuContainer = styled.div`
  display: none;
  z-index: 10000;
  ${StyledMe}:hover & {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: calc(100% + 11px);
  }
`;

export const StyledAvatarMenu = styled.ul`
  list-style-type: none;
  box-shadow: 5px 5px 50px -12px rgba(20, 27, 77, 0.3);
  border-radius: 16px;
  padding: 0 20px;
  margin: 0;
  background-color: #ffffff;

  li {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      border-bottom: 1px solid #41497e;
    }

    &:last-child:after {
      display: none;
    }

    span,
    a {
      display: inline-block;
      color: ${Color('#2B292D').alpha(0.7).toString()};
      font-family: 'Open Sans', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-decoration: none;
      white-space: nowrap;

      padding: 15px 0;

      &:hover {
        color: #2b292d;
      }
    }

    span {
      color: ${Color('#2B292D').alpha(0.5).toString()};
      padding: 5px 0;
      text-align: center;
      font-size: 12px;
      &:hover {
        color: ${Color('#2B292D').alpha(0.5).toString()};
      }
    }
  }
`;
