import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = { to: string; onClick?: never } | { to?: never; onClick: () => void };

export default function EditBtn({ to, onClick }: Props) {
  if (onClick) {
    return (
      <IconButton onClick={onClick} size="small" className="show-btn">
        <EditIcon />
      </IconButton>
    );
  }
  return (
    <IconButton component={Link} to={to} size="small" className="show-btn">
      <EditIcon />
    </IconButton>
  );
}
