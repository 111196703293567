export enum VoucherAccessoryColumn {
  /*
  NOM = 'nom',
  NOM2 = 'nom2',
  QTE = 'qte',
  MODELE = 'modele',
  */
  CUIR = 'cuir',
  TAILLE = 'taille',
  FIL = 'fil',
  DIVERS = 'divers',
}

export const voucherAccessoryColumnValues = Object.values(VoucherAccessoryColumn);
