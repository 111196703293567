import * as React from "react";
const SvgStockReceipt = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, "data-name": "019_transport", ...props }, /* @__PURE__ */ React.createElement("g", { style: {
  strokeWidth: 1.00002,
  strokeMiterlimit: 4,
  strokeDasharray: "none"
} }, /* @__PURE__ */ React.createElement("path", { d: "M2.72 2.86a.24.24 0 0 0-.24.24v1.2h-.24a.24.24 0 0 0-.24.24v1.92a.24.24 0 0 0 .005.046.72.72 0 0 0 .715.674.72.72 0 0 0 .679-.48h.562a.72.72 0 0 0 .679.48.72.72 0 0 0 .72-.72.72.72 0 0 0-1.395-.24h-.57a.72.72 0 0 0-.915-.435V4.78h.24a.24.24 0 0 0 .24-.24v-1.2h.59l.13.194V4.3h-.24a.24.24 0 0 0 0 .48h1.072l.409.612a.24.24 0 0 0 .398-.264l-.47-.704a.24.24 0 0 0-.02-.032l-.95-1.424a.24.24 0 0 0-.199-.108Zm3.12.48a.24.24 0 0 0-.24.24v2.88a.24.24 0 0 0 .24.24h.96a.24.24 0 0 0 0-.48h-.72V3.58a.24.24 0 0 0-.24-.24ZM2.72 6.22a.24.24 0 0 1 .24.24.24.24 0 1 1-.24-.24Zm1.92 0a.24.24 0 0 1 .24.24.24.24 0 1 1-.24-.24Z", style: {
  fill: "#000",
  fillOpacity: 0.541176,
  strokeWidth: 0.240002,
  strokeMiterlimit: 4,
  strokeDasharray: "none"
}, transform: "matrix(4.7619 0 0 4.7619 -9.524 -11.905)" })));
export default SvgStockReceipt;
