import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { I18n } from '@/enum';

/**
 * List of translations
 * Value MUST BE PATH OF JSON in /public/locale/xx/***.json
 */
export enum I18nNs {
  Alert = 'alert',
  Chat = 'use-case/chat',
  Common = 'common',
  Component = 'component',
  Compta = 'use-case/compta',
  Countries = 'countries',
  CreateOrderUseCase = 'use-case/create-order',
  Crud = 'crud',
  Customer = 'use-case/customer',
  CustomerAddressUseCase = 'use-case/customer-address',
  Dashboard = 'use-case/dashboard',
  Delivery = 'use-case/delivery',
  Enum = 'enum',
  Field = 'field',
  History = 'use-case/history',
  Logout = 'use-case/logout',
  Menu = 'use-case/menu',
  Order = 'use-case/order',
  OrderDelivery = 'use-case/order-delivery',
  OrderFrame = 'use-case/order-frame',
  OrderPayment = 'use-case/order-payment',
  OrderRecovery = 'use-case/order-recovery',
  OrderSecondhand = 'use-case/order-secondhand',
  PriceProduct = 'use-case/price-product',
  PriceType = 'use-case/price-type',
  Product = 'use-case/product',
  ProductBrand = 'use-case/product-brand',
  ProductType = 'use-case/product-type',
  PurchaseCategory = 'use-case/purchase-category',
  PurchaseOrder = 'use-case/purchase-order',
  PurchaseProduct = 'use-case/purchase-product',
  PurchaseStockTree = 'use-case/purchase-stock-tree',
  PurchaseSupplier = 'use-case/purchase-supplier',
  PurchaseUnit = 'use-case/purchase-unit',
  Repair = 'use-case/repair',
  Reseller = 'use-case/reseller',
  Secondhand = 'use-case/secondhand',
  SecondhandPlace = 'use-case/secondhand-place',
  ShowOrderUseCase = 'use-case/show-order',
  SearchSaddle = 'use-case/search-saddle',
  Sms = 'use-case/sms',
  Stat = 'use-case/stat',
  Sherlock = 'use-case/sherlock',
  UpdateOrderUseCase = 'use-case/update-order',
  User = 'use-case/user',
  Vat = 'use-case/vat',
  Voucher = 'use-case/voucher',
  VoucherColumn = 'use-case/voucher-column',
}

export type LNG = 'fr-FR' | 'en-US';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    supportedLngs: ['fr-FR', 'en-US'],
    missingKeyHandler: (lngs, ns, key) => {
      console.info(`Missing key ns: ${ns} key: ${key}`);
      Sentry.captureException(new Error(`Missing key ${ns} ${key}`), {
        extra: {
          metadata: {
            lngs: lngs.join(', '),
            ns,
            key,
          },
        },
      });
    },
    fallbackLng: ['fr-FR', 'en-US'],
    saveMissing: true,
    load: 'currentOnly',
    debug: false,
    ns: Object.values(I18nNs),
    preload: ['fr-FR', 'en-US'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const userLangToLng = (lang: any): LNG => {
  if (lang === I18n.FR) return 'fr-FR';
  return 'en-US';
};

export const lngToUserLang = (lng: any): I18n => {
  if (lng === 'fr-FR') return I18n.FR;
  return I18n.EN;
};

export const changeLanguage = (lang: I18n): void => {
  i18n.changeLanguage(userLangToLng(lang));
};

// export default i18n;
export { i18n };
