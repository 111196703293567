import styled from 'styled-components';

import { ChildericColor } from '@/util/theme';

export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 15px;
  min-width: 150px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  a {
    color: ${ChildericColor.Primary};
    span {
      display: block;
      font-size: 2rem;
      color: ${ChildericColor.Primary};
    }
  }

  @media (max-width: 1360px) {
    display: none;
  }
`;
