import * as yup from 'yup';

import { RepairStatus, repairStatusValues } from '@/enum/index.ts';

import { PageInput, pageValidator } from '../../common/index.ts';

export type ListRepairOrdersQueryInput = PageInput & {
  s?: string;
  saddleId?: string;
  status?: RepairStatus[];
};

export const listRepairOrdersQueryValidator = yup
  .object({
    s: yup.string().optional(),
    saddleId: yup.string().uuid().optional(),
    status: yup.array().of(yup.string().oneOf(repairStatusValues).required()).optional(),
  })
  .concat(pageValidator);
