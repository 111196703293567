 
import debug from 'debug';

export class Logger {
  log: debug.Debugger;

  info: debug.Debugger;

  warn: debug.Debugger;

  error: debug.Debugger;

  constructor(name: string) {
    this.log = debug(name);
    this.log.log = console.log.bind(console);

    this.info = debug(name);
    this.info.log = console.info.bind(console);

    this.warn = debug(name);
    this.warn.log = console.warn.bind(console);

    this.error = debug(name);
    this.error.log = console.error.bind(console);
  }
}
