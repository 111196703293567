import { compile, parse } from 'path-to-regexp';
import qs from 'qs';

const routes = new Map<string, ReturnType<typeof compile>>();
const params = new Map<string, (string | number | null)[]>();

export const getUrl = (url: string, dto?: any): string => {
  if (!dto) return url;
  if (typeof dto !== 'object') return url;

  Object.keys(dto).forEach((key) => {
    if (typeof dto[key] === 'number') {
      dto[key] = `${dto[key]}`;
    }
  });

  let baseOrigin: string | null = null;
  let baseParams = {};
  let pathname = url;
  if (url.match(/^https?:\/\//)) {
    const u = new URL(url);
    baseOrigin = u.origin;
    baseParams = Object.fromEntries(u.searchParams.entries());
    pathname = u.pathname;
  }

  if (!routes.has(url)) {
    routes.set(url, compile(pathname, { encode: encodeURIComponent }));
    params.set(
      url,
      parse(pathname)
        .tokens.map((el) => (typeof el === 'object' && el.type === 'param' ? el.name : null))
        .filter((el) => typeof el === 'string')
        .sort(),
    );
  }

  const fn = routes.get(url);
  if (!fn) throw new Error('Function not found');
  const keys = params.get(url);
  if (!keys) throw new Error('Keys not found');
  const u = fn(dto);

  let q = qs.stringify(
    Object.keys(dto)
      .filter((d) => !keys.includes(d))
      .reduce((acc, key) => {
        const value = dto[key];
        if (Array.isArray(value)) {
          if (value.length === 0) return acc;
          return { ...acc, [key]: value.join(',') };
        }
        if (value === null) {
          return { ...acc, [key]: 'null' };
        }
        return { ...acc, [key]: value };
      }, baseParams),
  );

  q = q === '' ? '' : `?${q}`;

  if (baseOrigin === null) return `${u}${q}`;

  return new URL(`${u}${q}`, baseOrigin).href;
};
