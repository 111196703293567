import { useMemo, useRef } from 'react';

export function usePersistentValue<T>(
  data: T | undefined,
  defaultValue: T,
  testExistsFunction?: (v: T) => boolean,
): T {
  const ref = useRef<T>(defaultValue);

  const test = useMemo(() => {
    if (typeof testExistsFunction !== 'function') {
      return (v: T) => !!v;
    }
    return testExistsFunction;
  }, [testExistsFunction]);

  const d = useMemo(() => (data !== undefined && test(data) ? data : ref.current), [test, data]);
  ref.current = d;
  return d;
}
