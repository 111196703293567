import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTitle } from '@/context';
import { I18nNs } from '@/util/i18n';

type Props = {
  value: string | null;
  ns?: I18nNs;
};

export default function Title({ value, ns }: Props): null {
  const { t } = useTranslation();
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle(ns ? t(value, { ns }) : value);
  }, [setTitle, value, ns, t]);

  return null;
}

export { Title };
