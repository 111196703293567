import { createContext } from 'react';

import { MenuLevel1, MenuLevel2 } from '@/enum';

// TODO : Bug when move in util/theme
export const colors = [
  '#1C9F95',
  '#2405A0',
  '#5380F1',
  '#3CA6C7', // #64BDD9',
  '#9083FF',
  '#8EBF02', // '#A7DD0C'
  '#EF8562',
  '#F18BB0',
  '#E89025', // #f6a034
];

export type HeaderContextType = {
  color: string;
  getColor: (menu: MenuLevel1) => string;
  isActive: (path: string) => boolean;
  level1Name: string | null;
  level1Path: MenuLevel1 | null;
  level2Name: string | null;
  level2Path: MenuLevel2 | null;
};

export const HeaderContext = createContext<HeaderContextType>({
  color: colors[0],
  getColor: () => colors[0],
  isActive: () => false,
  level1Name: null,
  level1Path: null,
  level2Name: null,
  level2Path: null,
});
