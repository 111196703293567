import { Link } from 'react-router-dom';

import { useHeader } from '@/context';

import { StyledBreadcrumbItem } from './Breadcrumb.style';

type Breadcrumb = {
  label: string;
  path?: string;
};

type Props = {
  item: Breadcrumb;
};

export function BreadcrumbItem({ item }: Props) {
  const { color } = useHeader();

  if (item.path) {
    return (
      <StyledBreadcrumbItem $color={color}>
        <Link to={item.path}>{item.label}</Link>
      </StyledBreadcrumbItem>
    );
  }

  return (
    <StyledBreadcrumbItem $color={color}>
      <span>{item.label}</span>
    </StyledBreadcrumbItem>
  );
}
