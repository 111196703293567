import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';

import { getHealthcheck } from '@/api/healthcheck/get-healthcheck';
import { useUserIsLabymind } from '@/hook/user';
import { config } from '@/util/config';
import { getDateTimeString } from '@/util/date';

export function BackupTestInfo() {
  const [show, setShow] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const goodUser = useUserIsLabymind();

  useEffect(() => {
    if (!goodUser) return;
    if (!show) return;

    getHealthcheck().then((res) => {
      setDate(res.lastHealthCheck);
    });
  }, [goodUser, show]);

  if (!goodUser) return null;

  if (config.ENV !== 'development') return null;

  return (
    <>
      <Box sx={{ position: 'fixed', left: 10, top: 90 }}>
        <IconButton size="small" onClick={() => setShow(true)}>
          <CloudSyncIcon fontSize="small" />
        </IconButton>
      </Box>
      {show ? (
        <Paper sx={{ mb: 3, p: 2 }}>
          {date ? getDateTimeString(date) : '-'} <br />
          <img src="/healthcheck.jpg" alt="healthcheck" />
        </Paper>
      ) : null}
    </>
  );
}
