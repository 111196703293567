type Store = {
  persist: {
    rehydrate: () => void;
    getOptions: () => { name?: string };
  };
};

const stores = new Map<string, Store>();

const isWeb = typeof window !== 'undefined';

if (isWeb && typeof window?.addEventListener === 'function') {
  window.addEventListener('storage', async (v) => {
    if (!v?.key) return;
    const store = stores.get(v.key);
    if (!store) return;
    store.persist.rehydrate();
  });
}

export const sync = (store: Store) => {
  if (!isWeb) return;
  const name = store.persist.getOptions().name;
  if (!name) {
    throw new Error('Sync store require a name in zustand options');
  }
  stores.set(name, store);
};
