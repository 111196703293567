import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BackupTestInfo from '@/component/BackupTestInfo';
import BreadcrumbItem from '@/component/BreadcrumbItem';
import Container from '@/component/Container';
import DeliveryOrderReadyMessage from '@/component/DeliveryOrderReadyMessage';
import PurchaseOrderAlertsMessage from '@/component/PurchaseOrderAlertsMessage';
import Title from '@/component/Title';
import { UserRole } from '@/enum';
import { useUserHasEveryRoles, useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { OrderList, StatSaleRollingYearByMonth } from './component';
import { StatNewCustomerByMonth } from './component/StatNewCustomerByMonth/StatNewCustomerByMonth';
import { StatSaleByCountry } from './component/StatSaleByCountry/StatSaleByCountry';
import { useKey } from './hook/useKey';

export default function DashboardScene() {
  const { t } = useTranslation([I18nNs.Dashboard, I18nNs.Stat]);
  const hasSomeRole = useUserHasSomeRole();
  const hasEveryRoles = useUserHasEveryRoles();
  const key = useKey();

  const title1 = t('title');
  const title2 = t('title.dashboard');

  return (
    <BreadcrumbItem label={title1} path="/dashboard" position={1}>
      <BreadcrumbItem label={title2} path="/dashboard" position={2}>
        <Title value={title2} />
        <Container key={key}>
          <Stack spacing={2} justifyContent="center" alignItems="stretch">
            <BackupTestInfo />

            {hasEveryRoles(UserRole.Admin, UserRole.Delivery) && (
              <Box>
                <DeliveryOrderReadyMessage />
              </Box>
            )}
            {hasEveryRoles(UserRole.Purchase) && <PurchaseOrderAlertsMessage />}

            {hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial) && (
              <Stack direction="row">
                <OrderList />
              </Stack>
            )}

            {hasSomeRole(UserRole.AdminReseller, UserRole.God) && (
              <>
                <Stack spacing={2} direction="row" sx={{ width: '100%' }}>
                  <Box sx={{ flex: 1, position: 'relative' }}>
                    <StatSaleRollingYearByMonth id="spark-a" />
                  </Box>
                  <Box sx={{ flex: 1, position: 'relative' }}>
                    <StatNewCustomerByMonth id="spark-b" />
                  </Box>
                  <Box sx={{ flex: 1, position: 'relative' }}>
                    <StatSaleByCountry id="spark-c" />
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </Container>
      </BreadcrumbItem>
    </BreadcrumbItem>
  );
}
