import * as yup from 'yup';

export type TreeSizeInput = {
  longueur?: number;
  largeur?: number;
  enfourchure?: number;
  ouvertureArcade?: number;
  hauteurArcade?: number;
  hauteurTroussequin?: number;
};

export const treeSizeValidator = yup.object({
  longueur: yup.number().optional(),
  largeur: yup.number().optional(),
  enfourchure: yup.number().optional(),
  ouvertureArcade: yup.number().optional(),
  hauteurArcade: yup.number().optional(),
  hauteurTroussequin: yup.number().optional(),
});
