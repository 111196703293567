import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const StyledLevel1 = styled(ScrollContainer)`
  list-style-type: none;
  display: flex;
  width: auto;
  overflow-x: scoll;
  margin: 0;
  padding: 0;
`;
