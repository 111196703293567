import React, { useCallback, useMemo, useState } from 'react';

import { BreadcrumbContext, BreadcrumbDto, BreadcrumbWithoutKeyDto } from './BreadcrumbContext.ts';

type Props = {
  children: React.ReactNode;
};

export function BreadcrumbProvider({ children }: Props): React.ReactElement {
  const [items, setItems] = useState<BreadcrumbDto[]>([]);

  const getKey = useCallback(
    (item: BreadcrumbWithoutKeyDto) => `${item.label}_${item.path}_${item.position}`,
    [],
  );

  const addItem = useCallback(
    (item: BreadcrumbWithoutKeyDto) => {
      const key = getKey(item);
      setItems((is) => {
        const exists = !!is.find((i) => i.key === key);
        if (exists) return is;
        return [...is, { ...item, key }].sort((a, b) => a.position - b.position);
      });
    },
    [getKey],
  );

  const removeItem = useCallback(
    (item: BreadcrumbWithoutKeyDto) => {
      const key = getKey(item);
      setItems((is) => is.filter((i) => i.key !== key));
    },
    [getKey],
  );

  const value = useMemo(
    () => ({
      items,
      addItem,
      removeItem,
    }),
    [items, addItem, removeItem],
  );

  return <BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>;
}
