import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import {
  ListRepairOrdersQueryInput,
  listRepairOrdersQueryValidator,
} from './list-repair-orders.input.ts';
import { ListRepairOrdersOutput } from './list-repair-orders.output.ts';

const path = '/repair-order';

export const listRepairOrders = async (
  query: ListRepairOrdersQueryInput,
): Promise<ListRepairOrdersOutput> => {
  try {
    await listRepairOrdersQueryValidator.validate(query);
    const url = getUrl(path, query);
    return await axios.get<ListRepairOrdersOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListRepairOrders = (
  query: ListRepairOrdersQueryInput,
): SWRResponse<ListRepairOrdersOutput> => {
  const url = getUrl(path, query);
  return swr<ListRepairOrdersOutput>(url, () => listRepairOrders(query));
};
