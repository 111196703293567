import * as React from "react";
const SvgStock = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 100, height: 100, viewBox: "0 0 26.458 26.458", ...props }, /* @__PURE__ */ React.createElement("g", { style: {
  strokeWidth: 1.00012501,
  strokeMiterlimit: 4,
  strokeDasharray: "none"
} }, /* @__PURE__ */ React.createElement("g", { fill: "param(fill) #fff", fillOpacity: "param(fillOpacity) .533333", style: {
  strokeWidth: 1.62369129,
  strokeMiterlimit: 4,
  strokeDasharray: "none"
} }, /* @__PURE__ */ React.createElement("path", { d: "M88.482 333.722v2.614h2.3v3.625h-2.3v2.613H129.999v-2.613h-2.3v-3.624h2.3v-2.614zm7.144 2.614h11.192v3.625H95.626v-3.624zm16.037 0h11.191v3.625h-11.191v-3.624zM88.483 314.014h-.001v17.459h20.115v-17.459h-6.83v4.385h-6.454v-4.385zM112.172 316.02v15.453H130v-15.452h-6.053v3.882h-5.72v-3.882zM88.482 299.62v11.904h12.746V299.62h-4.327v2.99h-4.09v-2.99z", fill: "param(fill) #fff", fillOpacity: "param(fillOpacity) 1", style: {
  stroke: "none",
  strokeWidth: 1.62369129,
  strokeMiterlimit: 4,
  strokeDasharray: "none"
}, transform: "matrix(.61596 0 0 .61596 -54.058 -184.553)" }))));
export default SvgStock;
