import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLevel2ItemLink = styled(Link)`
  margin: 0;
  padding: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(20, 27, 77, 0.07);

  &:hover {
    background: rgba(20, 27, 77, 0.14);
  }
`;

export const StyledLevel2Item = styled.li<{ $menuColor: string }>`
  display: block;
  flex: 1;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);

  font-family: 'Open Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  transform: font-weight 5s ease-in-out;

  svg,
  img {
    display: inline-block;
    margin-right: 10px;
    fill: rgba(0, 0, 0, 0.5);
  }

  &.is-active a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid ${({ $menuColor }) => $menuColor};
    color: #000000;
    font-weight: 700;
    background: rgba(20, 27, 77, 0.14);

    svg,
    img {
      fill: ${({ $menuColor }) => $menuColor};
    }
  }
`;
