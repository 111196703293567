import CategoryIcon from '@mui/icons-material/Category';
import DiscountIcon from '@mui/icons-material/Discount';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StraightenIcon from '@mui/icons-material/Straighten';
import { useTranslation } from 'react-i18next';

import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user';
import Stock from '@/svg/stock.svg?react';
import StockReceipt from '@/svg/stock_receipt.svg?react';
import { I18nNs } from '@/util/i18n';

import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Purchase() {
  const hasSomeRole = useUserHasSomeRole();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();

  if (level1Path !== MenuLevel1.Purchase) return null;
  if (!hasSomeRole(UserRole.Stock, UserRole.Purchase)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item
        roles={[UserRole.Purchase]}
        label={t('menu.purchase.order')}
        to={MenuLevel2.PurchaseOrder}
        icon={<ShoppingCartIcon />}
      />
      <Level2Item
        roles={[UserRole.God]}
        label={t('menu.purchase.unit')}
        to={MenuLevel2.PurchaseUnit}
        icon={<StraightenIcon />}
      />
      <Level2Item
        roles={[UserRole.God]}
        label={t('menu.purchase.category')}
        to={MenuLevel2.PurchaseCategory}
        icon={<DiscountIcon />}
      />
      <Level2Item
        roles={[UserRole.Purchase]}
        label={t('menu.purchase.supplier')}
        to={MenuLevel2.PurchaseSupplier}
        icon={<StorefrontIcon />}
      />
      <Level2Item
        roles={[UserRole.Purchase]}
        label={t('menu.purchase.product')}
        to={MenuLevel2.PurchaseProduct}
        icon={<CategoryIcon />}
      />

      <Level2Item
        roles={[UserRole.Stock]}
        label={t('menu.purchase.receipt')}
        to={MenuLevel2.PurchaseReceipt}
        icon={<StockReceipt width="24" height="24" />}
      />
      <Level2Item
        roles={[UserRole.Stock]}
        label={t('menu.purchase.stock')}
        to={MenuLevel2.PurchaseStock}
        icon={<Stock width="24" height="24" fillOpacity={0.5} />}
      />
      <Level2Item
        roles={[UserRole.Stock]}
        label={t('menu.purchase.tree')}
        to={MenuLevel2.PurchaseTree}
        icon={<img src="/arcon.svg" width="24" height="24" alt="Arçon" />}
      />
    </StyledLevel2>
  );
}
