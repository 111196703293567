import styled, { keyframes } from 'styled-components';

const pc = 3;

const animate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  ${100 - pc * 4}% {
    transform: rotate(0deg);
  }

  ${100 - pc * 3}% {
    transform: rotate(-5deg);
  }

  ${100 - pc * 2}% {
    transform: rotate(0deg);
  }

  ${100 - pc}% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);

  }
`;

export const Image = styled.img`
  animation: ${animate} 2s linear infinite;
`;

export const LazyContainer = styled.div`
  position: fixed;
  z-index: 1300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 50px;
  display: flex;
  justify-content: center;
  background-color: white;

  div {
    justify-content: center;
  }

  & > div {
    align-self: center;
  }

  p {
    padding: 0;
    margin: 0;
  }
`;
