import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { Logger } from '@/util/logger';

const logger = new Logger('hook:usePersistState');

export function usePersistState<T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>, boolean] {
  const [value, setValue] = useLocalStorage<T>(key, defaultValue);

  const onLsChange = useCallback(
    (e: StorageEvent) => {
      if (e.key !== key) return;
      if (e.newValue === null) return;
      try {
        const newValue = JSON.parse(e.newValue) as T;
        setValue(newValue);
      } catch (err) {
        logger.error(err);
      }
    },
    [key, setValue],
  );

  useEffect(() => {
    window.addEventListener('storage', onLsChange);
    return () => window.removeEventListener('storage', onLsChange);
  }, [onLsChange]);

  return [value as T, setValue as Dispatch<SetStateAction<T>>, false];
}
