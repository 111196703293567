export enum HistoryEntity {
  ComptaArticleFamily = 'compta_article_family',
  Config = 'config',
  Customer = 'customer',
  CustomerAddress = 'customer_address',
  Delivery = 'delivery',
  Order = 'order',
  OrderPayment = 'order_payment',
  OrderProduct = 'order_product',
  OrderRecovery = 'order_recovery',
  OrderSecondhand = 'order_secondhand',
  Payment = 'payment',
  PriceType = 'price_type',
  PurchaseCategory = 'purchase_category',
  PurchaseOrder = 'purchase_order',
  PurchaseOrderProduct = 'purchase_order_product',
  PurchaseProduct = 'purchase_product',
  PurchaseProductPackaging = 'purchase_product_packaging',
  PurchaseSupplier = 'purchase_supplier',
  PurchaseUnit = 'purchase_unit',
  RepairOrder = 'repair_order',
  RepairOrderOption = 'repair_order_option',
  RequestBy = 'request_by',
  Reseller = 'reseller',
  ResellerBy = 'reseller_by',
  Secondhand = 'secondhand',
  SecondhandPlace = 'secondhand_place',
  Tree = 'tree',
  User = 'user',
  Vat = 'vat',
  Voucher = 'voucher',
}

export const historyEntityValues = Object.values(HistoryEntity);
