export enum ChatType {
  CustomerReport = 'customer_report',
  MyNote = 'my_note',
  Order = 'order',
  OrderLine = 'order_line',
  OrderLineWorkshop = 'order_line_workshop',
  RepairOrder = 'repair_order',
  Tree = 'tree',
}

export const chatTypeValues = Object.values(ChatType);
