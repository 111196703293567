import * as Sentry from '@sentry/react';

export const handleError = async (
  path: string,
  err: Error | unknown,
  metadata?: { [key: string]: any },
) => {
  console.error({
    path,
    err,
    metadata,
  });

  Sentry.captureException(err, {
    extra: {
      path,
      metadata: metadata
        ? Object.keys(metadata).reduce((acc, key) => {
            if (!metadata) return acc;
            const value = metadata[key];

            if (['string', 'number', 'boolean'].includes(typeof value)) {
              return { ...acc, key: value };
            }

            return {
              ...acc,
              key: JSON.stringify(value),
            };
          }, {})
        : {},
    },
  });

  throw err;
};
