import * as yup from 'yup';

import {
  PaymentMethod,
  paymentMethodValues,
  PaymentStatus,
  paymentStatusValues,
  PaymentType,
  paymentTypeValues,
} from '@/enum/index.ts';

import { PageInput, pageValidator } from '../../common/index.ts';

export type ListPaymentsQueryInput = PageInput & {
  s?: string;
  status?: PaymentStatus[];
  type?: PaymentType[];
  method?: PaymentMethod[];
  dateLeft?: Date;
  dateRight?: Date;
};

export const listPaymentsQueryValidator = yup
  .object({
    s: yup.string().optional(),
    status: yup.array().of(yup.string().oneOf(paymentStatusValues).required()).optional(),
    type: yup.array().of(yup.string().oneOf(paymentTypeValues).required()).optional(),
    method: yup.array().of(yup.string().oneOf(paymentMethodValues).required()).optional(),
    dateLeft: yup.date().optional(),
    dateRight: yup.date().optional(),
  })
  .concat(pageValidator);
