import * as yup from 'yup';

export enum ENV {
  DEV = 'development',
  PROD = 'production',
  PREPROD = 'preprod',
}

type Config = {
  APP_VERSION: string;
  ENV: ENV;
  GOOGLE_API_KEY: string;
  SENTRY_DSN?: string;
  URL_API: string;
  URL_WWW: string;
};

const configValidator: yup.ObjectSchema<Config> = yup.object({
  APP_VERSION: yup.string().required(),
  ENV: yup.string().oneOf(Object.values(ENV)).required(),
  GOOGLE_API_KEY: yup.string().required(),
  SENTRY_DSN: yup.string().optional(),
  URL_API: yup.string().url().required(),
  URL_WWW: yup.string().url().required(),
});

export const config: Config = {
  APP_VERSION: import.meta.env.VITE_APP_VERSION || '-',
  ENV: (import.meta.env.VITE_ENV as ENV) || ENV.PROD,
  GOOGLE_API_KEY: import.meta.env.VITE_GOOGLE_API_KEY || '-',
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  URL_API: import.meta.env.VITE_URL_API || '-',
  URL_WWW: import.meta.env.VITE_URL_WWW || '-',
};

(async () => {
  try {
    await configValidator.validate(config);
  } catch (err) {
    console.error(err);
  }
})();
