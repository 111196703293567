import { Alert, Button, Stack, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';
import { Logger } from '@/util/logger';

import { ErrorFallbackContainer, Image } from './ErrorFallback.style';

const logger = new Logger('component/ErrorFallback');

type Props = {
  error: Error;
  resetErrorBoundary: () => any;
};

export function ErrorFallback({ error, resetErrorBoundary }: Props): ReactElement {
  const [showError, setShowError] = useState<boolean>(false);
  const { t } = useTranslation([I18nNs.Component, I18nNs.Common]);

  useEffect(() => {
    logger.error(error);
  }, [error]);

  return (
    <ErrorFallbackContainer>
      <div style={{ textAlign: 'center' }}>
        <Image src="/logo100.png" alt={t('logo', { ns: I18nNs.Common })} />
        <Alert severity="error" sx={{ mt: '20px' }}>
          {t('errorFallback.title')}
        </Alert>

        {showError && (
          <div style={{ paddingTop: '50px' }}>
            <Typography
              color="red"
              variant="body1"
              sx={{ textAlign: 'left', position: 'relative', left: '4px' }}
            >
              <>
                {error.name} - {error.message}
              </>
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: 'left', position: 'relative', left: '4px' }}
            >
              {error.stack?.toString()}
            </Typography>
          </div>
        )}
        <Stack direction="row" spacing={5} sx={{ mt: '50px' }}>
          {!showError && (
            <Button size="small" type="button" onClick={() => setShowError(true)}>
              {t('errorFallback.show')}
            </Button>
          )}
          <Button type="button" variant="contained" onClick={resetErrorBoundary}>
            {t('errorFallback.try.again')}
          </Button>
        </Stack>
      </div>
    </ErrorFallbackContainer>
  );
}
